import config from "App/lib/app-config";
import axios from "axios";
import { getStorage } from "App/utils";

let ax = axios.create({
  baseURL: config.api_baseurl,
  headers: {
    common: config.headers,
    post: {
      "Content-Type": config.headers["Content-Type"],
    },
  },
});

ax.interceptors.request.use(
  async (config) => {
    const userDetail = getStorage("currentUser", "object", {});
    config.headers.Authorization = userDetail?.token
      ? "Bearer " + userDetail.token
      : "";
    // config.headers.role = property_db_config.property_config.uac.role_id;
    // config.headers.propertyid = property_db_config.property_config.id;
    // config.headers.username = token.username;
    // if (token) {
    //     config.headers.authorization = 'Bearer ' + token;
    // }
    return config;
  },
  (error) => Promise.reject(error)
);

ax.interceptors.response.use(undefined, (error) => {
  const {
    response: { data, config },
    response,
  } = error;
  if (
    error &&
    error.response &&
    response.status === 401 &&
    !response.config?.url.includes("login") &&
    !(response.config?.url.search("change_password") > 0)
  ) {
    localStorage.clear();
    window.location.reload();
    return Promise.reject(data);
  }
  if (data) {
    return Promise.reject(data);
  } else {
    return Promise.reject(error);
  }
});

export default ax;
