
export const setStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
}

export const getStorage = (key) => {
    let value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
}

export const removeStorage = (key) => {
    let value = localStorage.removeItem(key)
    return value ? JSON.parse(value) : null
}

export const clearStorage = (callBack) => {
    var darkmode = localStorage.getItem('theme');
    localStorage.clear()
    if (darkmode) localStorage.setItem('theme', darkmode);
    // window.location.href="/login"
    if (callBack) {
        callBack()
    }
}