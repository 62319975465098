import React, { useCallback, useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import config from "App/lib/app-config";
import SocketEvent from 'App/context/SocketEvent';

function WebSocketContextComponent(props) {
  const closeAllRoom = useMemo(() => props.closeAllRoom, [props.closeAllRoom]);
  const marketList = props.marketList;
  const [connected, setConnected] = useState(false);
  const [roomCreated, setRoomCreated] = useState(false);
  useEffect(() => {
    if (props.socket.connected && marketList.length) {
      if (roomCreated) {
        createRoom(props.socket, marketList);
      } else {
        setTimeout(() => {
          createRoom(props.socket, marketList);
        }, 1000);
      }
    }
  }, [closeAllRoom, marketList, connected]);

  const createConnection = useCallback((socket) => {
    socket.on("connect", () => {
      setConnected(socket.connected);
      console.log("socket connected", socket.connected); // true
    });
    socket.on(SocketEvent.UPDATE_MARKET_ODDS, handleConnected);
    socket.on(SocketEvent.UPDATE_FANCY_ODDS, handleFancyOdds);
    socket.on(SocketEvent.ADD_UPDATE_FANCY, handleAppUpdateFancyOdds);
    socket.on(SocketEvent.UPDATE_SCORE, handleUpdateScore);
    socket.on(SocketEvent.SETTLEMENT_DONE, handleSettlementDone);
    socket.on(SocketEvent.UPDATE_COMMENTRY, handleCommentary);
    socket.on(SocketEvent.UPDATE_EVENT_ANNOUNCEMENT, handleEventAnnouncement);
    socket.on(SocketEvent.UPDATE_TV_URL, handleTVURL);
    socket.on(SocketEvent.UPDATE_LOCK_MARKET_BETTING, handleLocalMarketing);
    socket.on(SocketEvent.UPDATE_USER_LOCK_STATUS, handleUpdateUserLockStatus);
    socket.on(SocketEvent.UPDATE_FANCY_BET_LOCK, handleUpdateFancyBetLock);
    socket.on(SocketEvent.UPDATE_FANCY_SECTION_LOCK, handleUpdateFancySectionLock);
    socket.on(SocketEvent.RELOAD_PAGE, handleReloadPageStatus);
    socket.on(SocketEvent.ALL_ACTIVE_FANCY, handleAllActiveFancy);
    socket.on(SocketEvent.MARKET_ANNOUNCEMENT, handleMarketAnnouncement);
    props.setSocket(socket);
  }, []);

  useEffect(() => {
    let socket = io(config.socketurl, {
      reconnectionDelayMax: 100000,
      extraHeaders: {},
    });
    createConnection(socket);
    return () => {
      closeRoom(socket, marketList);
      socket.off(SocketEvent.UPDATE_MARKET_ODDS, handleConnected);
      socket.off(SocketEvent.UPDATE_FANCY_ODDS, handleFancyOdds);
      socket.off(SocketEvent.ADD_UPDATE_FANCY, handleAppUpdateFancyOdds);
      socket.off(SocketEvent.UPDATE_SCORE, handleUpdateScore);
      socket.off(SocketEvent.SETTLEMENT_DONE, handleSettlementDone);
      socket.off(SocketEvent.UPDATE_COMMENTRY, handleCommentary);
      socket.off(SocketEvent.UPDATE_EVENT_ANNOUNCEMENT, handleEventAnnouncement);
      socket.off(SocketEvent.UPDATE_TV_URL, handleTVURL);
      socket.off(SocketEvent.UPDATE_LOCK_MARKET_BETTING, handleLocalMarketing);
      socket.off(SocketEvent.UPDATE_USER_LOCK_STATUS, handleUpdateUserLockStatus);
      socket.off(SocketEvent.UPDATE_FANCY_BET_LOCK, handleUpdateFancyBetLock);
      socket.off(SocketEvent.UPDATE_FANCY_SECTION_LOCK, handleUpdateFancySectionLock);
      socket.off(SocketEvent.RELOAD_PAGE, handleReloadPageStatus);
      socket.off(SocketEvent.ALL_ACTIVE_FANCY, handleAllActiveFancy);
      socket.off(SocketEvent.MARKET_ANNOUNCEMENT, handleMarketAnnouncement);
      socket.off();
      socket.disconnect();
    };
  }, [createConnection]);

  const handleConnected = (payload) => {
    if (payload) {
      props.handleSocketData({ updateMarketOdds: payload });
    }
  };

  const handleFancyOdds = (payload) => {
    if (payload) {
      props.handleSocketData({ updateFancyOdds: payload });
    }
  };

  const handleAppUpdateFancyOdds = (payload) => {
    if (payload) {
      props.handleSocketData({ addUpdateFancy: payload });
    }
  };

  const handleUpdateScore = (payload) => {
    if (payload) {
      props.handleSocketData({ updateScore: payload });
    }
  };

  const handleCommentary = (payload) => {
    if (payload) {
      props.handleSocketData({ updateCommentary: payload });
    }
  };

  const handleEventAnnouncement = (payload) => {
    if (payload) {
      props.handleSocketData({ updateEventAnnouncement: payload });
    }
  };

  const handleTVURL = (payload) => {
    if (payload) {
      props.handleSocketData({ updateTVURL: payload });
    }
  };

  const handleLocalMarketing = (payload) => {
    if (payload) {
      props.handleSocketData({ updateLockMarketBetting: payload });
    }
  };

  const handleUpdateFancyBetLock = (payload) => {
    if (payload) {
      props.handleSocketData({ updateFancyBetLock: payload });
    }
  };
  const handleUpdateFancySectionLock = (payload) => {
    if (payload) {
      props.handleSocketData({ updateFancySectionLock: payload });
    }
  };

  const createRoom = (socket, market) => {
    for (let i = 0; i < market.length; i++) {

      if (props.event_id) {
        socket.emit("market_room", props.event_id + "_" + market[i]);
      } else {
        socket.emit("market_room", market[i]);
      }

    }
    setRoomCreated(true);
  };

  const closeRoom = (socket, market) => {
    socket.emit("unsubscribe", market);
  };

  const handleUpdateUserLockStatus = (payload) => {
    if (payload) {
      props.handleSocketData({ updateUserLockStatus: payload }); // not used in details page
    }
  };

  const handleReloadPageStatus = (payload) => {
    if (payload) {
      props.handleSocketData({ reloadPage: true });
    }
  };

  const handleSettlementDone = (payload) => {
    if (payload) {
      props.handleSocketData({ settlementDone: payload });
    }
  };

  const handleAllActiveFancy = (payload) => {
    if (payload) {
      props.handleSocketData({ allActiveFancy: payload });
    }
  };

  const handleMarketAnnouncement = (payload) => {
    if (payload) {
      props.handleSocketData({ marketAnnouncement: payload });
    }
  };

  return <></>;
}
export default React.memo(WebSocketContextComponent);
