
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { UserLayout } from 'App/Layout';
import { PrivateRoutes, PublicRoutes } from 'App/components';
import { routerList } from "App/router"
import _ from "App/helper/_"
import { useSelector } from 'react-redux';
import { getStorage } from "App/helper/storage";
import { useEffect } from 'react';
// import { messaging } from "./firebase";
// import { getToken } from "firebase/messaging";

function App() {

    // async function requestPermission() {
    //     const permission = await Notification.requestPermission();
    //     if (permission === "granted") {
    //         // Generate Token
    //         const token = await getToken(messaging, {
    //             vapidKey:
    //                 "",
    //         });
    //         console.log("Token Gen", token);
    //         // Send this token  to server ( db)
    //     } else if (permission === "denied") {
    //         alert("You denied for the notification");
    //     }
    // }

    // useEffect(() => {
    //     // Req user for notification permission
    //     requestPermission();
    // }, []);



    const { currentUser } = useSelector((state) => state.auth);
    let isAthenticated = false
    if (currentUser && currentUser.username != null) {
        isAthenticated = true
    } else {
        isAthenticated = false
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route >
                    <Route index element={<Navigate to="/login" replace />} />
                    {_.map(routerList, (route, idx) => {
                        return route.element ? route.private == 1 ?
                            <Route key={idx} path={route.path} element={<PrivateRoutes key={idx} isAthenticated={isAthenticated} />}>
                                <Route path={route.path} element={<route.element />} />
                            </Route>
                            :
                            <Route key={idx} path={route.path} element={<PublicRoutes key={idx} isAthenticated={isAthenticated} />}>
                                <Route key={idx} path={route.path} element={<route.element />} />
                            </Route>
                            : null
                    })}
                    <Route element={<PublicRoutes isAthenticated={isAthenticated} />}>
                        <Route path="*" element={<h1>Not Found</h1>} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;