import { BehaviorSubject } from 'rxjs';
import AuthHelp from 'App/helper/AuthHelp';

import axios from "axios";
import axiosAuth from "App/lib/axios";
import { toast } from "react-hot-toast";
import config from "App/lib/app-config";
import { getStorage, setStorage } from "App/helper/storage";
import { clearStorage } from "App/helper/storage";
import API from "App/lib/api-urls"
import { updateUserBalance } from "App/_redux/_actions"
import { useDispatch } from 'react-redux';




const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

function login(username, password, location, isp) {
  return new Promise((resolve, reject) => {
    let param = {
      username,
      password,
      device_type: "",
      device_id: "",
      location,
      isp
    }
    axios.post(API.LOGIN, param).then((response) => {
      AuthHelp.setAuth(response.data.token);
      setStorage('currentUser', response.data)
      currentUserSubject.next(response.data);
      window.location = '/home';
      return resolve(response);
    })
      .catch((error) => {
        return reject(error);
      });

  })
}

function logout() {
  // remove user from local storage to log user out
  let param = {
    Sessionkey: AuthHelp.getAuth()
  }
  axios.post(API.LOGOUT, param).then((response) => {
    AuthHelp.removeAuth()
    currentUserSubject.next(null);
    window.location = '/login';
  })
    .catch((error) => {
    });


}


function updateBalance(balance, exposure, bonus_balance, withdrawal_balance, wa_number, wa_visible_to, parent_role) {
  let user = { ...currentUserSubject.value, balance, exposure, bonus_balance, withdrawal_balance, wa_number, wa_visible_to, parent_role }
  setStorage('currentUser', user)
  currentUserSubject.next(user);




}
// function updateLedgerBalance(ledger_balance, updated_ledger_balance) {
//   let user = { ...currentUserSubject.value, ledger_balance, updated_ledger_balance }
//   localStorage.setItem('currentUser', JSON.stringify(user));
//   currentUserSubject.next(user);
// }
// function updateSetting(sport_settings) {
//   let user = { ...currentUserSubject.value, sport_settings }
//   localStorage.setItem('currentUser', JSON.stringify(user));
//   currentUserSubject.next(user);
// }
// function updateSportsSetting(sport_settings) {
//   let user = { ...currentUserSubject.value, sport_settings }
//   localStorage.setItem('currentUser', JSON.stringify(user));
//   currentUserSubject.next(user);
// }

// function updateUserStackSetting(type, stack) {
//   let user = { ...currentUserSubject.value, [type]: stack }
//   localStorage.setItem('currentUser', JSON.stringify(user));
//   currentUserSubject.next(user);
// }

export default {

  login,
  logout,
  updateBalance,
  // updateSetting,
  // updateUserStackSetting,
  // updateLedgerBalance,
  // updateSportsSetting,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
}

