
import React, { useEffect, useState, Fragment } from "react";
import { beforeLoginInplayFixtureRequest, beforeLoginUpcomingFixtureRequest, toggleAuthModalRequest } from "App/_redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { LoginModal } from "App/modals";
import MatkaMatchList from "./MatkaMatchList"

import { Utils, isWebview } from "App/utils";

import { Timer, FixtureListView, SportTabs, CasinoMarket, CasinoSidebar } from "App/components";
import _ from "App/helper/_";
import { Images } from "App/constants";
import { DotLoader } from "react-spinners";
import Footer from "App/components/Layout/Footer";
import { useScreenDetector } from "App/hooks/useScreenDetector";


const Login = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { isMobile } = useScreenDetector();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const { currentUser } = useSelector((state) => state.auth);
    const { selectedSportId, selectedSportTab, GlobalAnnouncement } = useSelector((state) => state.match);
    const { isLoader } = useSelector((state) => state.beforeLogin);

    const [Events, setEvent] = useState([])
    const [state, setState] = useState({
        sport_id: "",
        current_page: 1,
        items_perpage: 100,
        active_tab: "",
        activeTab: 1
    })

    useEffect(() => {
        let is_ref = searchParams.get('ref')
        if (is_ref) {
            toggleAuthModal('REGISTER')
        }
    }, [])

    useEffect(() => {
        let param = {
            "sport_id": selectedSportId.sports_id ? selectedSportId.sports_id : "",
            "current_page": state.current_page,
            "items_perpage": state.items_perpage
        }

        if (selectedSportTab.link === "/all-sports") {
            param['all_event'] = 1
        }

        if (selectedSportTab.link === "/cup-winner") {
            param['cup_event'] = 1
        }
        // if (selectedSportId.sports_id == 4 && selectedSportId.sports_name === "Election") {
        if (selectedSportId.sports_id == 333) {
            param['election_event'] = 1
        }
        if (state) {
            if (location.pathname === "/up-coming") {
                dispatch(beforeLoginUpcomingFixtureRequest(param, (res) => { if (res) setEvent(res) }));
            }
            else if (selectedSportTab.link === "/all-sports")
                dispatch(beforeLoginInplayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
            else if (selectedSportTab.link === "/inplay")
                dispatch(beforeLoginInplayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
            else if (location.pathname === "/in-play")
                dispatch(beforeLoginInplayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
            else if (selectedSportTab.link === '/upcoming')
                if (selectedSportId.sports_id === 999) {
                    dispatch(beforeLoginInplayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                }
                else {
                    dispatch(beforeLoginUpcomingFixtureRequest(param, (res) => { if (res) setEvent(res) }));
                }
            else if (selectedSportTab.link === '/cup-winner')
                dispatch(beforeLoginInplayFixtureRequest(param, (res) => { if (res) setEvent(res) }));
            else if (selectedSportTab.link === '/casino') { console.log() }
            else {
                toggleAuthModal("LOGIN")
            }
        } else {
            navigate(-1);
        }

    }, [selectedSportTab, selectedSportId]);




    const gotoDetail = (event_id) => {
        if (currentUser.username) {
            navigate(`/match-detail/${event_id}`)
        } else {
            // navigate(`/match-detail/${event_id}`)
        }
    }

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    return (
        <Fragment>
            <div className=" midLayout h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar">
                <div className="flex anvMarquee  h-[28px] md:h-7 items-center md:my-1 md:rounded overflow-hidden">
                    <img className="h-4 px-1" src={Images.INFO.default} />
                    <marquee
                        className="text-[12px] flex items-center font-semibold "
                        behavior=""
                        direction=""
                    >
                        {GlobalAnnouncement != null ? GlobalAnnouncement.client_msg : `Welcome to Velocity999 !!`}
                    </marquee>
                </div>
                <SportTabs />
                <div className="dark:bg-[#0B0D0E]">
                    <div className="md:pt-3 pt-2">
                        <div className="max-w-[1409px] mx-auto ">
                            {isLoader ?
                                <div className="flex justify-center items-start text-center py-12  h-screen">
                                    <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
                                        <DotLoader color="#0b81db" />
                                    </div>
                                </div>

                                : selectedSportTab.link == "/casino" ?
                                    <div className="text-center text-red-500 py-12">
                                        <h2>Please login </h2>
                                    </div>
                                    : Events && Events.total_records && Events.total_records > 0 ?
                                        <Fragment>
                                            {Events ?
                                                <Fragment>
                                                    {(selectedSportId.sports_id === 999 || selectedSportId.sports_id === 777) ?
                                                        <MatkaMatchList eventList={Events?.records ? Events?.records : []} />
                                                        :
                                                        <FixtureListView EventDetails={Events} location={location} selectedSportTab={selectedSportTab} currentUser={currentUser} />

                                                    }
                                                </Fragment>
                                                :
                                                <div className="text-center dark:text-white py-12">
                                                    <h4>Coming soon...</h4>
                                                </div>
                                            }
                                        </Fragment>
                                        : Number(Events.total_records) === 0 ?
                                            <div className="text-center text-red-500 py-12">
                                                <h2> No Matches Found !!!</h2>
                                            </div> : null
                            }


                            <div className="lg:hidden" >
                                <br />
                                <CasinoMarket />
                            </div>

                            {/* <div className="flex gap-[18px] flex-wrap pb-12">
                                <h4 className="text-[21px] text-[#262626] font-semibold basis-full dark:text-white pl-4 pt-3">
                                    Our Live Casinos
                                </h4>

                                <div className="ourCasinos_list overflow-scroll will-change-transform px-4">
                                    <div className="flex gap-[18px] flex-wrap w-[1025px] md:w-auto">
                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino1.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino2.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino3.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino4.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino5.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino6.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino7.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino8.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden">
                                            <img src="casino9.png" alt="" className="w-full" />
                                        </div>

                                        <div className="basis-[calc(17%-14.4px)] md:basis-[calc(20%-14.4px)] rounded-[8px] overflow-hidden relative">
                                            <img src="casino10.png" alt="" className="w-full" />
                                            <img
                                                className="absolute right-0 bottom-1"
                                                src="inrCasino-10.png"
                                                alt=""
                                            />
                                        </div>

                                    </div>
                                </div>

                            </div> */}

                            {/* <div className="flex gap-[18px] flex-wrap pb-20">
                                <h4 className="text-[21px] text-[#262626] font-semibold basis-full dark:text-white">
                                    Sports
                                </h4>

                                <div className="min-h-[224px] rounded-[8px] overflow-hidden">
                                    <img src="sp-cricket.png" alt="" className="w-full" />
                                </div>

                                <div className="min-h-[224px] rounded-[8px] overflow-hidden">
                                    <img src="sp-soccer.png" alt="" className="w-full" />
                                </div>

                                <div className="min-h-[224px] rounded-[8px] overflow-hidden">
                                    <img src="sp-tennis.png" alt="" className="w-full" />
                                </div>

                                <div className="min-h-[224px] rounded-[8px] overflow-hidden">
                                    <img src="sp-kabaddi.png" alt="" className="w-full" />
                                </div>

                                <div className="min-h-[224px] rounded-[8px] overflow-hidden">
                                    <img src="sp-politics.png" alt="" className="w-full" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <Footer />
            </div>

            <div className="xl:block hidden mt-2">
                {/* <div className="bg-[#fff] dark:bg-[#22262A] p-[6px_8px] rounded">
                    <div className="bg-[#EDEDED] dark:bg-[#32383E] flex justify-center items-center min-h-[45px] rounded-[5.63px] gap-1">
                        <div className="text-[15.75px] text-[#000000] dark:text-[#F9FAFA] font-medium">Bet slip</div>
                        <div className=" bg-[#F2D61D] dark:bg-[#F9FAFA] w-[18px] h-[18px] flex justify-center items-center rounded-full">
                            <span className="text-[#3E4750] text-[13.5px] leading-[10px] font-bold">0</span>
                        </div>
                    </div>

                    <div className="text-center p-[30px]">
                        <h4 className="text-[20.25px] text-[#000000] dark:text-[#F9FAFA] font-medium mb-2">Bet slip is empty</h4>
                        <p className="text-[15.75px] text-[#5F5F5F] dark:text-[#CFD4D8] font-medium">Click on the odds to add selections to your bet slip</p>
                    </div>
                </div> */}

                {/* <div className="pt-[16px]">
                    <a href="">
                        <img src={Images.PLAY_NOW} alt="" />
                    </a>
                </div> */}
                <div className="w-[368px] xl:block hidden h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar">
                    <CasinoSidebar />
                </div>
            </div>
        </Fragment>

    )
}

export default Login