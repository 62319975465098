import React, { useEffect, useState } from "react";
import moment from "moment";
import { getDateAndTime, getLeftBazarTime } from "App/utils";

function MatkaHeader(props) {
  const { matchDetail, setBetClosed, betClosed } = props;
  const [bazarTime, setBazarTime] = useState({
    left: "00:00:00",
    currentTime: new Date(),
    bet_sec: "",
  });

  useEffect(() => {
    if (matchDetail?.event?.open_date) {
      const clearTime = setInterval(function () {
        setBazarTime({
          ...getLeftBazarTime(
            getDateAndTime("YYYY-MM-DD HH:mm:ss", matchDetail?.event?.open_date)
          ),
          currentTime: moment(),
        });
      }, 1000);
      return () => {
        clearInterval(clearTime);
      };
    }
  }, [matchDetail?.event?.open_date]);

  useEffect(() => {
    if (matchDetail?.event && bazarTime?.bet_sec !== "") {
      switch (true) {
        case +bazarTime?.bet_sec === 0:
          setBetClosed(0);

          break;
        case !betClosed &&
          bazarTime?.bet_sec <= matchDetail?.event?.bet_close_time:
          setBetClosed(true);

          break;
      }
    }
  }, [bazarTime?.bet_sec]);

  return (
    <div className="">
      <div className="bg-[#1A1A1A] rounded-[8px_8px_0px_0px] overflow-hidden">
        <div
          className="match-score-div1 "

        >
          {matchDetail?.event?.league_name
            ? matchDetail?.event?.league_name.toUpperCase()
            : '--'}
        </div>

        <div className="score_timeBx">
          <div className="match-score-div2">
            {matchDetail?.event?.event_name
              ? matchDetail?.event?.event_name.toUpperCase()
              : '--'}
          </div>
          <div className="innr_scoreTime">

            <div className="flex flex-wrap">
              <p className="basis-[50%]">
                <span className="text-base text-white">
                  <b>Bazar Date :{" "}</b>
                  {matchDetail?.event?.open_date
                    ? getDateAndTime("DD, MMM YYYY", matchDetail?.event?.open_date)
                    : "-- -- --"}
                </span>
              </p>
              <p className="basis-[50%] text-right">
                <span className="text-base text-white">
                  <b>Bazar Time :{" "}</b>
                  {matchDetail?.event?.open_date
                    ? getDateAndTime("h:mm A", matchDetail?.event?.open_date)
                    : "-- -- --"}
                </span>
              </p>
            </div>

            <div className="flex flex-wrap">
              <p className="basis-[50%]">
                <span className="text-base text-white">
                  <b>Current Time :{" "}</b>
                  {bazarTime?.currentTime
                    ? moment(bazarTime?.currentTime).format("hh:mm:ss")
                    : "-- -- --"}
                </span>
              </p>
              <p className="basis-[50%] text-right">
                <span
                  className={
                    "text-base text-white" +
                    (bazarTime?.bet_sec !== "" &&
                      bazarTime?.bet_sec < matchDetail?.event?.bet_close_time
                      ? " text-danger"
                      : "")
                  }
                >
                  <b>Bazar Left Time :</b> {bazarTime.left}{" "}
                </span>

                {matchDetail?.event?.announcement && (
                  <span className="text-base text-white">
                    {matchDetail?.event?.announcement}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MatkaHeader);
