// import axios from "axios";
import axiosAuth from "App/lib/axios";
import { toast } from "react-hot-toast";
import config from "App/lib/app-config";
// import { getStorage } from "../utils";
import API from "App/lib/api-urls";


export const beforeLoginInplayFixture = (data) => {
    return axiosAuth
        .post(API.NON_LOGIN_INPLAY_FIXTURES, data, {
            headers: config.headers,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};

export const beforeLoginUpcomingFixture = (data) => {
    return axiosAuth
        .post(API.NON_LOGIN_UPCOMING_FIXTURES, data, {
            headers: config.headers,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};

export const beforeLoginMatchDetail = (data) => {
    return axiosAuth
        .post(API.NON_LOGIN_MATCH_DETAIL, data, {
            headers: config.headers,
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};