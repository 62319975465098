import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import _ from "App/helper/_";

const NavBar = () => {
    const { currentUser } = useSelector((state) => state.auth);

    return (
        <div className='custom-navbar'>
            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/all-sports' : '/login'} activeClass="active" >Exchange</NavLink>
            <NavLink to={!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino' : '/casino-market'} activeClass="active" >Live Casino</NavLink>
            <NavLink to="/casino/provider/SN" activeClass="active" >Supernowa</NavLink>
            <NavLink to="/casino/provider/XPG" activeClass="active" >XProgaming</NavLink>
            <NavLink to="/casino/provider/AVA" activeClass="active" >Aviator</NavLink>
            <NavLink to="/casino/provider/QT" activeClass="active" >Qtech</NavLink>
            <NavLink to="/indian-casino" activeClass="active" >Indian Casino</NavLink>
        </div>
    )
}

export default NavBar