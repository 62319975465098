import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { UserLayout } from 'App/Layout'

const PrivateRoutes = ({ isAthenticated }) => {
    if (!isAthenticated) {
        return <Navigate to={"/login"} />
    }

    return <UserLayout><Outlet /></UserLayout>
}
export default PrivateRoutes