import axios from "axios";
import axiosAuth from "App/lib/axios";
import { toast } from "react-hot-toast";
import config from "App/lib/app-config";
import { getStorage, } from "App/utils";
import { clearStorage } from "App/helper/storage";
import API from "App/lib/api-urls"
const { REACT_APP_GOOGLE_KEY } = process.env

export const userLogin = (postData) => {
  return axios
    .post(API.LOGIN, postData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err.error)
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const registerUser = (postData) => {
  return axios
    .post(API.REGISTER, postData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err.error)
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const MobileLogin = (postData) => {
  return axios
    .post(API.MOBILE_LOGIN, postData)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      toast.error(err.error)
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const logout = () => {
  const userDetail = getStorage("currentUser", "object", {});
  config.headers.Authorization = userDetail?.token
    ? "Bearer " + userDetail.token
    : "";
  return axios
    .post(API.LOGOUT, {}, { headers: config.headers })
    .then((response) => {
      clearStorage()
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const changePasswordLogin = (data) => {
  return axiosAuth
    .post(config.api_baseurl + "/change_password", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


export const getUserLocation = (ip) => {
  return axios
    .get(`https://pro.ip-api.com/json/${ip}?key=${REACT_APP_GOOGLE_KEY}`)
    .then((response) => {
      if (response?.status === 200) {
        return response?.data
      }
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUserIP = () => {
  // https://geolocation-db.com/json/
  return axios
    .get('https://api.db-ip.com/v2/free/self')
    .then((response) => {
      if (response?.status === 200) {
        return response?.data

      }
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const sideMaintenance = (postData) => {
  return axios
    .post(config.api_baseurl + "maintenance_mode", postData)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};