import React from "react";
import ReactPaginate from "react-paginate";

export default function CoustomPagination({
  handlePageClick = (page) => console.log("test", page),
  pageCount = 10,
  initialPage,
}) {
  return (
    <ReactPaginate
      className="anvTab_pagination"
      breakLabel="..."
      nextLabel=">"
      forcePage={initialPage}
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
    />
  );
}
