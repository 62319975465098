import _ from "lodash";

const DevHost = ["gamebey.betexchange.guru", "www.gamebey.betexchange.guru"];

const StagHost = [
  "dhan7771.com",
  "www.dhan7771.com"
];
const LiveHost = [
  "localhost",
  "gamebey.com",
  "www.gamebey.com"
];
export const allowedWidgetDomains = [
  "http://localhost:3003/",
  "http://localhost:3002",
];

const hostname = window.location.hostname;

const s3_url = "https://paribetstatic.s3.ap-south-1.amazonaws.com/"
const s3_prefix = "velocity_"

const regex =
  /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm;

const live = {
  api_baseurl: "https://clientapi.gamebey.com/api",
  baseurl: "https://clientapi.gamebey.com/",
  socketurl: "https://node.gamebey.com/",
  s3_url: "https://gamebeyfiles.s3.ap-south-1.amazonaws.com/",
  s3_prefix: "gb_",
  environment: 'live',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://gamebey-client-api.betexchange.guru/api",
  baseurl: "https://gamebey-client-api.betexchange.guru/",
  socketurl: "https://node-gamebey.betexchange.guru/",
  s3_url,
  s3_prefix,
  environment: 'dev',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const stag = {
  api_baseurl: "https://clientapi.gamebey.com/api",
  baseurl: "https://clientapi.gamebey.com/",
  socketurl: "https://node.gamebey.com/",
  s3_url: "https://gamebeyfiles.s3.ap-south-1.amazonaws.com/",
  s3_prefix: "gb_",
  environment: 'live',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dynamicServer = {
  api_baseurl: "https://clientapi.gamebey.com/api",
  baseurl: "https://clientapi.gamebey.com/",
  socketurl: "https://node.gamebey.com/",
  s3_url: "https://gamebeyfiles.s3.ap-south-1.amazonaws.com/",
  s3_prefix: "gb_",
  environment: 'live',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};


const AppConfig = _.includes(DevHost, hostname) || hostname.match(regex)
  ? dev
  : _.includes(LiveHost, hostname)
    ? live
    : _.includes(StagHost, hostname)
      ? stag
      : dynamicServer;

export default AppConfig;
