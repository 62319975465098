import { authConstants } from "App/_redux/_constants";
import { getStorage } from "App/utils";

let initialState = {
  currentUser: getStorage("currentUser", "object", {}),
  isLoader: false,
  authModal: null //value ==> LOGIN or REGISTER

};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.TOGGLE_LOGIN_FORM_REQUEST:
      return {
        ...state,
        authModal: action.data,
      };
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.LOGIN_SUCCESS:
      localStorage.setItem("currentUser", JSON.stringify(action.data));
      return {
        ...state,
        currentUser: action.data,
        isLoader: false,
      };
    case authConstants.REGISTER_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.REGISTER_SUCCESS:
      localStorage.setItem("currentUser", JSON.stringify(action.data));
      return {
        ...state,
        currentUser: action.data,
        isLoader: false,
      };
    case authConstants.LOGOUT_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: {},
        isLoader: false,
      };
    //
    case authConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        currentUser: action.data,
        isLoader: false,
      };
    //
    case authConstants.FAILED_RESPONSE:
      return {
        ...state,
        isLoader: false,
      };
    case authConstants.UPDATE_USER_BALANCE:
      return {
        ...state,
        currentUser:
          { ...state.currentUser, balance: action.data.balance, exposure: action.data.exposure, bonus_balance: action.data.bonus_balance, withdrawal_balance: action.data.withdrawal_balance },
        isLoader: false,
      };
    default:
      return state;
  }
}
