export const beforeLoginConstants = {

    BEFORE_LOGIN_UPCOMING_FIXTURE_REQUEST: "BEFORE_LOGIN_UPCOMING_FIXTURE_REQUEST",
    BEFORE_LOGIN_UPCOMING_FIXTURE_SUCCESS: "BEFORE_LOGIN_UPCOMING_FIXTURE_SUCCESS",

    BEFORE_LOGIN_INPLAY_FIXTURE_REQUEST: "BEFORE_LOGIN_INPLAY_FIXTURE_REQUEST",
    BEFORE_LOGIN_INPLAY_FIXTURE_SUCCESS: "BEFORE_LOGIN_INPLAY_FIXTURE_SUCCESS",

    BEFORE_LOGIN_GET_MATCH_DETAIL_REQUEST: "BEFORE_LOGIN_GET_MATCH_DETAIL_REQUEST",
    BEFORE_LOGIN_GET_MATCH_DETAIL_SUCCESS: "BEFORE_LOGIN_GET_MATCH_DETAIL_SUCCESS",

    FAILED_RESPONSE: "FAILED_RESPONSE",
};
