import axiosAuth from "App/lib/axios";
import { toast } from "react-hot-toast";
import config from "App/lib/app-config";
import API from "App/lib/api-urls";

export const saveBankDetail = (data) => {
    return axiosAuth
        .post(
            API.SAVE_BANK_DETAIL, data,
            { headers: config.headers }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};

export const userBankDetail = (data) => {
    return axiosAuth
        .post(
            API.USER_BANK_DETAIL, data,
            { headers: config.headers }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};

export const withDrawRequest = (data) => {
    return axiosAuth
        .post(
            API.WITHDRAW_REQUESTS, data,
            { headers: config.headers }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};
export const withDrawListRequest = (data) => {
    return axiosAuth
        .post(
            API.LIST_WITHDRAW_REQUESTS, data,
            { headers: config.headers }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            if (err && err.message === "Network Error") {
                toast.error("Please check your network connection.");
            }
            throw err;
        });
};
