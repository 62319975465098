import config from "./_apiConfig/apiConfig";
import { performRequest, apiEndPoints, methodType } from "./_apiConfig";
import API from "App/lib/api-urls"

export const loginRequest = (data = {}) => {
    return performRequest(
        methodType.POST, (config.baseURL + apiEndPoints.authLogin), data, false
    ).then((response) => {
        return response
    }).catch((error) => {
        return error
    });
};

export const signupRequest = (data = {}, callback) => {
    return performRequest(
        methodType.POST, (API.REGISTER), data, false
    ).then((response) => {
        callback(response)
        return response
    }).catch((error) => {
        return error
    });
};

export const otpVerificationRequest = (data = {}, callback) => {
    return performRequest(
        methodType.POST, (API.SEND_OTP), data, false
    ).then((response) => {
        callback(response)
        return response
    }).catch((error) => {
        return error
    });
};
export const mobileLoginRequest = (data = {}, callback) => {
    return performRequest(
        methodType.POST, (API.MOBILE_LOGIN), data, false
    ).then((response) => {
        callback(response)
        return response
    }).catch((error) => {
        return error
    });
};

export const changePasswordRequest = (data = {}) => {
    return performRequest(
        methodType.POST, (config.baseURL + apiEndPoints.authChangePassword), data, true
    ).then((response) => {
        return response
    }).catch((error) => {
        return error
    });
};

export const resetPasswordRequest = (data = {}) => {
    return performRequest(
        methodType.POST, (config.baseURL + apiEndPoints.resetPassword), data, false
    ).then((response) => {
        return response
    }).catch((error) => {
        return error
    });
};

export const resendEmailOtpRequest = (data = {}) => {
    return performRequest(
        methodType.POST, (config.baseURL + apiEndPoints.resendEmailOtp), data, false
    ).then((response) => {
        return response
    }).catch((error) => {
        return error
    });
};

export const verifyPasswordResetRequest = (data = {}) => {
    return performRequest(
        methodType.POST, (config.baseURL + apiEndPoints.verifyPasswordReset), data, false
    ).then((response) => {
        return response
    }).catch((error) => {
        return error
    });
};
