import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PrivacyPolicy = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Privacy Policy</h1>

      <section className="mb-8">
        <p className="mb-4">
          The Gamebey management team and its employees take the safety of your personal data and general confidentiality very seriously. We are committed to improving the transparency of how we use and protect your data. We have included changes to this Privacy Policy to comply with the standards of the General Data Protection Regulation (GDPR).
        </p>
        <p className="mb-4">
          This Privacy Policy sets out our data processing methods and your options concerning how your personal information is used. Gamebey understands and takes responsibility for providing you, our customers, with clear information about how we use cookies, and we guarantee that people using our website can make the right choice.
        </p>
        <p className="mb-4">
          If you have any requests, questions, or suggestions regarding your personal data or any general questions regarding methods of processing and using the services that are provided to you, please contact us at <a href="mailto:support.india@gamebey.com" className="text-blue-500 hover:underline">support.india@gamebey.com</a>.
        </p>
        <p>
          We also kindly ask that you send detailed information to <a href="mailto:support.india@gamebey.com" className="text-blue-500 hover:underline">support.india@gamebey.com</a> about any pages on our website where you are concerned about the use of cookies.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
