import React, { useEffect, useState } from "react";
// import { placeMatkaBet } from "../../_services";
import { toast } from "react-hot-toast";
import { appendStackValue } from "App/utils";
import { FloatingField } from "../MatchDetail/FloatingField";
import { placeMatkaBet } from "App/_redux/_services";
import ClipLoader from "react-spinners/ClipLoader";
import { LoginModal } from "App/modals";
import { useDispatch } from "react-redux";
import { toggleAuthModalRequest } from "App/_redux/_actions";

export const MatkaBetUI = (props) => {
  const dispatch = useDispatch();
  const { marketList, event, marketType } = props;
  const [matkaMarketList, setMarketList] = useState([]);
  const [, setForceUpdate] = useState(false);
  const [loading, setLoader] = useState(false);



  const toggleAuthModal = (type) => {
    dispatch(toggleAuthModalRequest(type))
  }

  useEffect(() => {
    setMarketList(marketList ? getMarketList(marketList) : []);
  }, [marketList]);

  const getDisplayValue = (value, market_type, isLagai) => {
    try {
      if (value) {
        if (market_type) {
          if (
            market_type === 1 ||
            market_type === 4 ||
            market_type === 5 ||
            market_type === 6 ||
            market_type === 7
          ) {
            if (market_type == 1 && isLagai && value > 1.2) {
              value = value - 0.01;
            }

            let val = value * 100;
            let calValue = ((parseFloat(val) - 1 * 100) / 100).toFixed(2);

            return calValue;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val.toFixed(2)) - 1 * 100;
          } else {
            return value;
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const getMarketList = (list) => {
    for (let i = 0; i < list.length; i++) {
      let runners = list[i].runners;
      const isSameMarket =
        matkaMarketList[i]?.market_name === list[i]?.market_name;
      let showLagaiOnly = false;
      let lagaiBhav1 = 0;
      let lagaiBhav2 = 0;
      if (runners) {
        for (let j = 0; j < runners.length; j++) {
          let resData = isFavTeam(list[i], runners, j);
          runners[j].isFav = resData.isFav;
          // if (matkaRunner && matkaRunner[j]?.betValue) {
          //   runners[j].betValue = matkaRunner[j]?.betValue;
          // }
          if (!showLagaiOnly) {
            showLagaiOnly = resData.showLagaiOnly;
            lagaiBhav1 = resData.lagaiBhav1;
            lagaiBhav2 = resData.lagaiBhav2;
          }
        }
        if (showLagaiOnly) {
          list[i].showLagaiOnly = showLagaiOnly;
          list[i].lagaiBhav1 = lagaiBhav1;
          list[i].lagaiBhav2 = lagaiBhav2;
        }
        list[i].runners = runners;
        list[i].totalRowBet =
          isSameMarket && matkaMarketList[i]?.totalRowBet
            ? matkaMarketList[i]?.totalRowBet
            : [...Array(runners?.length / 10)].map((_, ind) => ({
              [ind + 1]: "",
            }));
        list[i].topInputRow =
          isSameMarket && matkaMarketList[i]?.topInputRow
            ? matkaMarketList[i]?.topInputRow
            : [...Array(10)].map((_, ind) => ({ [ind + 1]: "" }));
        list[i].totalBetAmount = isSameMarket
          ? matkaMarketList[i]?.totalBetAmount
          : "";
        list[i].allBazar = isSameMarket ? matkaMarketList[i]?.allBazar : "";
      }
    }
    return list;
  };

  const showMarket = (market) => {
    let show = false;
    if (market) {
      for (let i = 0; i < market.runners.length; i++) {
        if (market.runners[i].lock_team_betting == 0) {
          show = true;
          break;
        }
      }
    }
    return show;
  };

  const isFavTeam = (market, runners, index) => {
    const minBhavLimit = 0.96;
    const maxBhavLimit = 1.0;
    let showLagaiOnly = false;
    let lagaiBhav1 = 0;
    let lagaiBhav2 = 0;
    let lagai1 = getDisplayValue(
      runners[0]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let lagai2 = getDisplayValue(
      runners[1]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let isFav = false;
    let resultData = {};
    const isSoccer = event?.sport_id == 1;
    if (isSoccer) {
      if (market.market_type != 1) {
        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        }
        if (
          market.market_type == 5 ||
          market.market_type == 6 ||
          market.market_type == 7
        ) {
          showLagaiOnly = true;
          lagaiBhav1 = lagai1;
          lagaiBhav2 = lagai2;
        }
        resultData = {
          isFav: isFav,
          showLagaiOnly: showLagaiOnly,
          lagaiBhav1: lagaiBhav1,
          lagaiBhav2: lagaiBhav2,
        };
      }
    } else {
      if (runners.length == 3) {
        //TEST MATCH

        let lagai3 = getDisplayValue(
          runners[2]?.runner_odd?.ex?.availableToBack[0]?.price,
          market.market_type,
          true
        );
        let khai3 = getDisplayValue(
          runners[2]?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            // showLagaiOnly = true;
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            //  showLagaiOnly = true;
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 2) {
          if (khai3 > 9.99 && lagai3 > 9.99) {
            isFav = false;
          } else {
            isFav = true;
          }
        }
      } else if (runners.length == 2) {
        //Other MATCH

        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        }
      }
      if (market.market_type == 2) {
        // if (market.runner_odds[index]?.status == 'ACTIVE') {
        const back = runners[index]?.runner_odd?.ex?.availableToBack[0]?.price;
        const lay = runners[index]?.runner_odd?.ex?.availableToLay[0]?.price;
        const selectedRunner = market.runner_odds.find(
          (item) => runners[index]?.selectionId == item?.selectionId
        );
        if (selectedRunner?.status == "ACTIVE" && (lay || back)) {
          if (lagai1 < 100 && lagai1 > 95) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai1;
            showLagaiOnly = true;
            isFav = false;
          } else if (lagai2 < 100 && lagai2 > 95) {
            showLagaiOnly = true;
            lagaiBhav1 = lagai2;
            lagaiBhav2 = lagai2;
            isFav = false;
          } else {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
            isFav = true;
            showLagaiOnly = false;
          }
        } else {
          isFav = false;
        }
      }

      if (market.market_type == 2 && lagai1 >= 94) {
        showLagaiOnly = true;
        isFav = false;
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
      }

      if (
        market.market_type == 5 ||
        market.market_type == 6 ||
        market.market_type == 7
      ) {
        showLagaiOnly = true;
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
      }
      resultData = {
        isFav: isFav,
        showLagaiOnly: showLagaiOnly,
        lagaiBhav1: lagaiBhav1,
        lagaiBhav2: lagaiBhav2,
      };
    }
    return resultData;
  };

  const getRowBetSum = (runners, index, rowLength) => {
    return runners.reduce((total, item, ind) => {
      if (runners.length === 10 || ind % rowLength === index) {
        total += item?.betValue ? +item?.betValue : 0;
        return total;
      } else {
        return total;
      }
    }, 0);
  };

  const onBetValueChange = (type, mIndex, value, index, runnerName) => {
    value = value === "" ? value : +value;
    setMarketList((s) => {
      let totalRowBet = [...s[mIndex]?.totalRowBet];
      let topInputRow = [...s[mIndex]?.topInputRow];
      const runners = s[mIndex].runners.map((run) => {
        let num1 =
          ("" + run.runnerName).split("").reduce((a, b) => +a + +b, 0) + "";
        const condition =
          marketType === "Jodi"
            ? runnerName === +run.runnerName.charAt(0) || // condition for jodi
            (runnerName === 10 && +run.runnerName.charAt(0) === 0) // last index item condition
            : ("" + runnerName).length === 1
              ? runnerName === +num1.charAt(num1.length - 1)
              : runnerName === 10 && +num1.charAt(num1.length - 1) === 0; // last index item condition
        switch (true) {
          case type === "innerInput"
            ? runnerName === +run.runnerName
            : condition:
            return { ...run, betValue: value };
          case type === "allBazar":
            return { ...run, betValue: value };
          default:
            return run;
        }
      });
      if (type === "innerInput") {
        const rootIndex = index % marketList[mIndex]?.totalRowBet?.length;
        totalRowBet.splice(rootIndex, 1, {
          [rootIndex + 1]: getRowBetSum(runners, rootIndex, totalRowBet.length),
        });
      } else {
        totalRowBet = totalRowBet.map((row, index) => {
          return {
            [index + 1]: getRowBetSum(runners, index, totalRowBet.length),
          };
        });
      }
      if (type === "topInput" || type === "allBazar") {
        if (type === "allBazar") {
          topInputRow = topInputRow.map((item, ind) => ({
            [ind + 1]: value,
          }));
        } else {
          topInputRow.splice(runnerName - 1, 1, { [runnerName]: value });
        }
      }
      const stateObj = {
        ...s[mIndex],
        topInputRow,
        totalRowBet,
        totalBetAmount: totalRowBet.reduce(
          (total, item, ind) => (total += +item[ind + 1]),
          0
        ),
        runners,
      };
      if (type === "allBazar") {
        stateObj.allBazar = value;
      }
      s.splice(mIndex, 1, stateObj);
      return s;
    });

    setForceUpdate((s) => !s);
  };
  const matkaMinMAx = (matka_id, format) => {
    let min = "0",
      max = "0";
    switch (true) {
      case matka_id == 1:
        max = event?.akda_max_match_stack;
        min = event?.akda_min_match_stack;
        break;
      case matka_id == 5:
        max = event?.jodi_max_match_stack;
        min = event?.jodi_min_match_stack;
        break;
      default:
        max = event?.max_other_market_stack;
        min = event?.min_other_market_stack;
        break;
    }
    if (format) {
      return `Min:${appendStackValue("" + min)} | Max:${appendStackValue(
        "" + max
      )}`;
    } else {
      return { min, max };
    }
  };

  const checkMinMax = (valueArray, matka_id) => {
    const { min = 0, max = 0 } = matkaMinMAx(matka_id);
    const isValueMinMax = valueArray.every((val) => +val >= min && +val <= max);
    if (isValueMinMax) {
      return true;
    } else {
      toast.error(`Bet amount min value is ${min} and max value is ${max}`);
      return false;
    }
  };

  const placeBet = async (
    eventId,
    marketId,
    runner,
    matka_market_id,
    market_name
  ) => {
    if (
      checkMinMax(
        runner.filter((item) => item?.betValue).map((item) => item?.betValue),
        matka_market_id
      ) === true
    ) {
      setLoader(true);
      localStorage.setItem("matka_market_id", market_name);
      const payload = {
        event_id: eventId,
        market_id: matka_market_id,
        bets: runner
          .filter((item) => item?.betValue)
          .map((item) => ({
            selection_id: item?.selectionId,
            stake: item?.betValue,
            market_id: marketId,
          })),
      };
      try {
        const res = await placeMatkaBet(payload);
        if (res) {
          toast.success(res?.message ? res?.message : "success");
          setTimeout(() => {
            // setLoader(false);
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        if (error.error !== "") {
          toast.error(error.error)
        } else {
          toast.error(
            error.response?.data?.error
              ? error.response?.data?.error
              : "server error"
          );
        }
        setLoader(false);
      }
    }
  };

  const returnTableRowCount = (market) => {
    return market?.market_name === "Double Patti"
      ? 9
      : market?.market_name === "Single Patti"
        ? 12
        : market?.market_name === "Jodi"
          ? 10
          : 10;
  };

  return (
    <>
      <div className="" style={{ paddingTop: "8px" }}>
        {matkaMarketList.map(
          (market, mIndex) =>
            showMarket(market) && (
              <table
                className="table "
                key={market.market_name}
              >
                <thead>
                  <tr className="anv_trHead">
                    <th
                      colSpan="12"
                      className="bet-place-tbl-th market_type_row market-name-row-color font-sans-serif ls-2"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {market.market_name}
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            fontSize: 10,
                            display: "flex",
                            alignItems: "center",
                            paddingTop: 3,
                          }}
                        >
                          {matkaMinMAx(market?.matka_market_id, true)}
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td colSpan={12} className="dark:bg-[#191919]">
                      <div>
                        <div className="all_bazar_bet">
                          <div className="all_bazar_bx">
                            <h4 className="flex items-center text-color">Total :</h4>
                            <div className="column_value_item max-w-[130px]">
                              <input className="h-full w-full border-[1px] rounded-[3px] px-1 border-[#767676]" value={market?.totalBetAmount} disabled />
                            </div>
                            <button
                              disabled={
                                !market?.runners.some(
                                  (item) => !!item?.betValue
                                ) || loading
                              }
                              amt-value="clear"
                              className="submit bet_btn "
                              onClick={() => { toggleAuthModal("LOGIN") }}
                            >
                              {loading && (
                                <ClipLoader
                                  color={"#ffffff"}
                                  loading={true}
                                  size={20}
                                />
                              )}{" "}
                              Submit
                            </button>
                            <button
                              disabled={
                                !market?.runners.some(
                                  (item) => !!item?.betValue
                                )
                              }
                              amt-value="clear"
                              className="clear bet_btn"
                              onClick={() => {
                                onBetValueChange(
                                  "allBazar", // type
                                  mIndex,
                                  ""
                                );
                              }}
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="runner_container pt-2">
                          <div
                            className={
                              (market?.market_name === "Single Akda"
                                ? " runner_value_Singleakda "
                                : " ") +
                              (market?.market_name === "Single Patti"
                                ? " runner_value_singlepatti "
                                : " ") +
                              (market?.market_name === "Triple Patti"
                                ? " runner_value_Singleakda "
                                : " ") +
                              (market?.market_name === "Jodi"
                                ? " runner_value_jodi "
                                : " ") +
                              (market?.market_name === "Double Patti"
                                ? " runner_value_Double "
                                : " ") +
                              " runner_value_box"
                            }
                          >
                            {market.runners.map(
                              (run, index) =>
                                run.lock_team_betting == 0 && (
                                  <React.Fragment
                                    key={
                                      mIndex / index +
                                      1 +
                                      index +
                                      run.runnerName
                                    }
                                  >
                                    {(index + returnTableRowCount(market)) %
                                      returnTableRowCount(market) ===
                                      0 &&
                                      market.runners.length > 10 && (
                                        <div className="matka_top_input_box">
                                          <FloatingField
                                            focus={true}
                                            class="matka_top_input"
                                            type="number"
                                            value={
                                              market?.topInputRow[
                                              (index +
                                                returnTableRowCount(market)) /
                                              returnTableRowCount(market) -
                                              1
                                              ]?.[
                                              "" +
                                              (index +
                                                returnTableRowCount(
                                                  market
                                                )) /
                                              returnTableRowCount(market)
                                              ]
                                            }
                                            onChange={(e) => {
                                              onBetValueChange(
                                                "topInput", // type
                                                mIndex,
                                                !isNaN(e.target.value) ||
                                                  e.target.value === ""
                                                  ? e.target.value.length <= 5
                                                    ? e.target.value
                                                    : market?.topInputRow[
                                                    (index +
                                                      returnTableRowCount(
                                                        market
                                                      )) /
                                                    returnTableRowCount(
                                                      market
                                                    ) -
                                                    1
                                                    ]?.[
                                                    "" +
                                                    (index +
                                                      returnTableRowCount(
                                                        market
                                                      )) /
                                                    returnTableRowCount(
                                                      market
                                                    )
                                                    ]
                                                  : "",
                                                returnTableRowCount(market) /
                                                returnTableRowCount(market),
                                                (index +
                                                  returnTableRowCount(market)) /
                                                returnTableRowCount(market)
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                    <div
                                      className={
                                        (market?.market_name === "Double Patti"
                                          ? "jodi_box "
                                          : "") + "runner_value_item"
                                      }
                                    >
                                      <FloatingField
                                        focus={true}
                                        boxClass="matka_input_box"
                                        // type="number"
                                        label={run.runnerName}
                                        value={run?.betValue}
                                        onChange={(e) => {
                                          onBetValueChange(
                                            "innerInput", // type
                                            mIndex,
                                            !isNaN(e.target.value) ||
                                              e.target.value === ""
                                              ? e.target.value.length <= 5
                                                ? e.target.value
                                                : run?.betValue
                                              : "",
                                            index,
                                            +run.runnerName
                                          );
                                        }}
                                      />
                                    </div>
                                    {(index + 1) %
                                      returnTableRowCount(market) ===
                                      0 ? (
                                      <div className="separator_box_line" />
                                    ) : (
                                      <></>
                                    )}
                                  </React.Fragment>
                                )
                            )}
                            {market?.totalRowBet?.length >= 9 &&
                              market?.totalRowBet?.map((item, ind) => (
                                <React.Fragment>
                                  {ind === 0 && (
                                    <h4 className="total_amount_text">
                                      Amount
                                    </h4>
                                  )}
                                  <div className={"total_row_amount"}>
                                    <FloatingField
                                      focus={true}
                                      boxClass="matka_input_box"
                                      className="matka_total_box"
                                      value={item[ind + 1]}
                                      disabled
                                      style={{
                                        padding: "10px",
                                        textAlign: "center",
                                      }}
                                    />
                                  </div>
                                </React.Fragment>
                              ))}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )
        )}
      </div>
    </>
  );
};