import { Images } from "App/constants";
import { IoMdHome, IoMdPlayCircle, IoMdTrophy, IoMdFootball, IoIosTennisball } from "react-icons/io";
import { GoStarFill } from "react-icons/go";
import { BiSolidCricketBall } from "react-icons/bi";
import { PiSoccerBallFill, PiSelectionInverseFill } from "react-icons/pi";
import { GiHorseHead } from "react-icons/gi";
import { RiBilliardsFill } from "react-icons/ri";


export const menuitem = {
    sidebarItems: [
        { name: "Home", icon: Images.HOME.default, link: "/all-sports" },
        { name: "In-Play", icon: Images.PLAY.default, link: '/inplay', type: 1 },
        // { name: "Upcoming", icon: Images.PLAY.default, link: '/upcoming', type: 1 },
        { name: "Favorite", icon: Images.STAR.default, link: "/favorite" },
        { name: "Casino", icon: Images.CASINO.default, link: "/casino" },

        // {
        //     name: "Top Leagues",
        //     icon: "cricket-ball_1.svg",
        //     link: "/",
        //     submenu: true,
        //     subMenuItems: [
        //         { name: "BBL 5", icon: <IoMdTrophy />, link: "/" },
        //         { name: "Champions league", icon: <IoMdFootball />, link: "/" },
        //     ]
        // },
        {
            name: "Sports",
            icon: "cricket-ball_1.svg",
            link: "",
            submenu: true,
            subMenuItems: [
                { name: "Cricket", count: "6", icon: Images.CRICKET.default, sport_id: 4 },
                { name: "Soccer", count: "4", icon: Images.SOCCER.default, sport_id: 1 },
                { name: "Tennis", count: "1", icon: Images.TENNIS.default, sport_id: 3 },
                // { name: "Kabaddi", count: "3", icon: "kabbadi_1.svg", sport_id: 11 },
                { name: "Election", count: "5", icon: Images.POLITICS.default, sport_id: 8 },
                { name: "Horse racing", count: "2", icon: Images.HORSE.default, sport_id: 9 },
                { name: "Snooker", count: "7", icon: Images.BILLIARD.default, sport_id: 999 },
            ]
        },
    ]
};
