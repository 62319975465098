import { createBrowserRouter, Navigate } from "react-router-dom";
import { getStorage } from "./helper/storage";
import { routerList, PublicRoute, ProtectRoutes } from "./router";
const loggedIn = getStorage('currentUser')

let list = routerList.map((route) => ({
    ...route, element:
        route.private ? <ProtectRoutes showSidebar={route?.sidebar ?? 1}><route.element /></ProtectRoutes> :
            <PublicRoute><route.element /></PublicRoute>
}))
export const routers = createBrowserRouter([
    // {
    //     path: "/",
    //     element: (
    //         <Navigate to={loggedIn ? '/home' : "/home"} />
    //     ),
    // },
    ...list, // render public and private route
    {
        path: "*",
        element: (
            <Navigate to={loggedIn ? '/' : "/"} />
        ),
    },
]);
export default routers;
