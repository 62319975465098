import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ConfirmModal(props) {
  const {
    className,
    isOpen,
    onClose,
    onSubmit,
    message,
    btnTitle,
    isLoading,
    isDisabled,
  } = props;
  return (
    <div>
      <Modal
        size="md"
        isOpen={isOpen}
        toggle={onClose}
        className={className}
        backdrop
        keyboard
      >
        <ModalHeader toggle={onClose}>Confirm Delete</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="secondary" className="anvConf_cancelBtn" onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button
            className="anvConf_yesBtn"
            color="primary"
            onClick={onSubmit}
            disabled={isLoading || isDisabled}
          >
            {btnTitle ? btnTitle : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmModal;
