import { userBetList } from "App/_redux/_services";
import CustomTable from "App/components/Common/CoustomTabel";
import { getDateAndTime, settleList } from "App/utils";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";
import { DotLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';

const BetHistory = () => {
    const navigate = useNavigate()
    const { masterData } = useSelector((state) => state.match);

    const formatDate = "YYYY-MM-DD";
    const [dataList, setDataList] = useState({ total_count: 0, list: [] });
    const [loading, setLoading] = useState(false);
    const todayDate = moment(new Date()).startOf("isoWeek");
    const [filterProps, setFilterProps] = useState({});
    const [filterData, setFilter] = useState({
        sports_id: 4,
        filter: 1,
        from_date: todayDate.clone().subtract(12, "days").format("YYYY-MM-DD"),
        to_date: moment(new Date()).format("YYYY-MM-DD"),
        bookmaker_type: "2",
        current_page: 1,
        items_perpage: "20",
    });

    const [forceFetch, setToggleFetch] = useState(false);

    useEffect(() => {
        if (forceFetch) {
            getDataList(1);
        }
    }, [forceFetch]);

    useEffect(() => {
        getDataList(filterData?.current_page);
    }, [filterData?.current_page]);

    const getDataList = async (current_page) => {
        const formattedEndDate =
            moment(filterData?.to_date).format(formatDate) + " " + "23:59:59";
        const formattedStartDate =
            moment(filterData?.from_date).format(formatDate) + " 00:00:00";
        const payload = {
            sports_id: filterData?.sports_id,
            filter: filterData?.filter,
            to_date: moment(formattedEndDate)
                .utc()
                .format(formatDate + " HH:mm:ss"),
            from_date: moment(formattedStartDate)
                .utc()
                .format(formatDate + " HH:mm:ss"),
            bookmaker_type: filterData?.bookmaker_type,
            current_page: current_page,
            items_perpage: filterData?.items_perpage,
        };
        setLoading(true);

        try {
            const res = await userBetList(payload);
            if (res?.data?.bet_data) {
                setFilterProps(payload);
                setDataList({
                    total_count: res?.data?.total_records,
                    list: res?.data?.bet_data.map((item) => ({
                        ...item,
                        event_name: <b>{item?.event_name}</b>,
                        profit_loss: (
                            <>
                                {item.profit_loss > 0 ? (
                                    <span style={{ color: "Green", fontWeight: "600" }}>
                                        {item.profit_loss}
                                    </span>
                                ) : null}

                                {item.profit_loss < 0 ? (
                                    <span style={{ color: "red", fontWeight: "600" }}>
                                        {item.profit_loss}
                                    </span>
                                ) : null}
                            </>
                        ),
                    })),
                });
                setLoading(false);
            } else {
                setDataList({ total_count: 0, list: [] });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
        setToggleFetch(false);
        setFilter((s) => ({ ...s, current_page }));
    };
    const data = [
        { name: "Event Name", value: "event_name" },
        { name: "Market Name", value: "market_name" },
        { name: "Selection", value: "team_name" },
        // { name: "Type", value: "betting_type" }, //betting_type = 1 : Back else Lay
        filterProps?.bookmaker_type == 3
            ? { name: "Run", value: "line" }
            : { name: "Odds", value: "display_price" },
        { name: "Stake", value: "stake" },
        { name: "Place Time", value: "created_at" },
        //   {  name:"Type              - betting_type = 1 : Yes else No
        // { name: "Profit/Loss", value: "profit_loss" },
    ];

    const RowElement = ({ item, column }) => (
        <tr>
            {column?.map((colItem, i) => {
                return (
                    <>
                        <td key={i}>{item[`${colItem.value}`]}</td>
                    </>
                );
            })}
        </tr>
    );

    const handleSelectDate = (name, value) => {
        setFilter((s) => ({ ...s, [name]: value }));
    };

    const handlePageClick = ({ selected }) => {
        setFilter((s) => ({ ...s, current_page: selected + 1 }));
    };

    const columnDataList = (data) => {
        return data && data?.length > 0
            ? data?.map((item) => ({
                ...item,
                display_price: Number(item?.display_price).toFixed(2),
                created_at: getDateAndTime("DD-MM-YYYY, hh:mm A", item?.created_at),
            }))
            : [];
    };


    return (
        <div className="innrRight_singleBx">
            <div className="bg-secondary text-secondary-foreground p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
                <h4 className="h4_semiTxt flex items-center gap-2"> <AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Bet History</h4>

                <div className="filter_box filter_boxGrid">
                    <div className="filterFx_basis filterFx_basis_full">
                        <div className="mainDate_picBx" >
                            <select className="cstFrm_inputs form-control" name="bookmaker_type"
                                onChange={(e) => handleSelectDate("bookmaker_type", e.target.value)}
                            >
                                <option value="2">Market</option>
                                <option value="3">Fancy</option>
                            </select>
                            <BsChevronDown className="anvChevron" />
                        </div>
                    </div>

                    <div className="filterFx_basis " >
                        <div className="mainDate_picBx" >
                            <select
                                className="cstFrm_inputs form-control" name="sports_id"
                                value={filterData?.sports_id}
                                onChange={(e) => handleSelectDate("sports_id", e.target.value)}
                            >
                                {masterData && masterData.sports_master && masterData.sports_master.map((sport) => (
                                    <option key={sport?.sport_id} value={sport?.sport_id}>
                                        {sport.sports_name == "Soccer" ? "FOOTBALL" : sport.sports_name}
                                    </option>
                                ))}
                            </select>


                            <BsChevronDown className="anvChevron" />
                        </div>
                    </div>

                    <div className="filterFx_basis " >
                        <div className="mainDate_picBx" >
                            <select
                                className="cstFrm_inputs form-control" name="filter"
                                value={filterData?.filter}
                                onChange={(e) => handleSelectDate("filter", e.target.value)}
                            >
                                {settleList.map((item, indexVal) => (
                                    <option key={indexVal} value={item?.value}>
                                        {item?.label}
                                    </option>
                                ))}
                            </select>
                            <BsChevronDown className="anvChevron" />
                        </div>
                    </div>

                    <div className="filterFx_basis" >
                        <div className="mainDate_picBx" >
                            <input type="date" className="cstFrm_inputs form-control"
                                value={filterData?.from_date}
                                max={moment(new Date()).format("YYYY-MM-DD")}
                                onChange={(e) => handleSelectDate("from_date", e.target.value)}
                            />

                            <SlCalender className="dateSvg" />

                        </div>
                    </div>

                    <div className="filterFx_basis" >
                        <div className="mainDate_picBx" >
                            <input type="date" className="cstFrm_inputs form-control"
                                value={filterData?.to_date}
                                min={filterData?.from_date}
                                max={moment(new Date()).format("YYYY-MM-DD")}
                                onChange={(e) => handleSelectDate("to_date", e.target.value)}
                            />

                            <SlCalender className="dateSvg" />

                        </div>
                    </div>

                    <div className="d-flxe gap-1 filterFx_basis filterFx_basis_full" >
                        <button type="button" className="_btnSave h-full" disabled={loading} onClick={() => setToggleFetch(true)}>Get History</button>
                    </div>
                </div>
            </div>

            {loading ?
                <div className="flex justify-center items-start text-center py-12">
                    <div className="px-24 py-8 bg-secondary flex justify-center items-center">
                        <DotLoader color="#0b81db" />
                    </div>
                </div>
                :
                <div className="table_controler">
                    <CustomTable
                        columnData={data}
                        // dataList={dataList.list}
                        dataList={columnDataList(dataList.list)}
                    />
                </div>}

        </div>
    );
};

export default BetHistory