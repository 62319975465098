import { casinoLogin, getAwsCasino, getMasterData } from 'App/_redux/_services'
import React, { Fragment, useState } from 'react'
import _ from "App/helper/_";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuthModalRequest } from 'App/_redux/_actions';
import { Link } from 'react-router-dom';

const CasinoMarket = ({ sideBarCasino }) => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const { masterData } = useSelector((state) => state.match);

    const [state, setStateValue] = useState({
        casinoList: [],
        casinoUrl: null
    })


    const setState = (obj, callback) => {
        setStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const fetchCasino = async (provider_code) => {
        // let param = { provider_code }
        // try {
        //     const res = await casinoLogin(param);
        //     if (res.data.url) {
        //         setState({ casinoUrl: res.data.url })
        //     }

        // } catch (error) {
        //     console.log("err")
        // }
    }


    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }

    const { casinoUrl } = state
    return (
        <Fragment>
            {casinoUrl != null ?
                <Fragment>
                    <iframe className='w-full h-screen' src={casinoUrl} frameborder="0"></iframe>
                </Fragment> :

                masterData && masterData.casino_master && masterData.casino_master.length > 0 ?
                    <Fragment>
                        <div className="flex gap-[18px] flex-wrap pb-12 px-1">
                            {!sideBarCasino && <h4 className="text-[21px] text-[#262626] font-semibold basis-full dark:text-white">
                                Casinos
                            </h4>}
                            {masterData.casino_master.map((item, key) => {
                                return (
                                    <Fragment key={key}>
                                        {sideBarCasino ?
                                            <div className="rounded-[8px] overflow-hidden w-full h-[220px]" onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? fetchCasino(item.provider_code) : toggleAuthModal("LOGIN") }}>
                                                <img src={item.image} alt="" className="" />
                                            </div>

                                            : <Link to={`/casino/provider/${item.provider_code}`} onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? fetchCasino(item.provider_code) : toggleAuthModal("LOGIN") }}
                                                className="cursor-pointer basis-[calc(50%-9.4px)] sm:basis-[calc(33.33%-12.4px)] md:basis-[calc(25%-14.4px)] rounded-[8px] overflow-hidden">
                                                <img src={item.image} alt="" className="w-full" />
                                                <div className='bg-stone-900 dark:bg-slate-500 text-white text-center p-1'>{item.name}</div>
                                            </Link>}
                                    </Fragment>
                                )
                            })}

                        </div>
                    </Fragment> :
                    null}
        </Fragment>
    )
}

export default CasinoMarket