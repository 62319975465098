import React from "react";

const Sidebar = () => {
  return (
    <div className="sidebar ">
      -----------------------sidebar------------------
    </div>
  );
};

export default Sidebar;
