import { beforeLoginConstants } from "../../_constants";
import { beforeLoginInplayFixture, beforeLoginUpcomingFixture, beforeLoginMatchDetail } from "../../_services";
import { toast } from 'react-toastify';

export const beforeLoginInplayFixtureRequest = (data, callback) => {
    return (dispatch) => {
        dispatch({ type: beforeLoginConstants.BEFORE_LOGIN_INPLAY_FIXTURE_REQUEST, data: {} });
        beforeLoginInplayFixture(data).then((response) => {
            callback(response.data);
            dispatch({ type: beforeLoginConstants.BEFORE_LOGIN_INPLAY_FIXTURE_SUCCESS, data: response.data });
        }).catch((error) => {
            dispatch({ type: beforeLoginConstants.FAILED_RESPONSE, data: error });
        });
    };
};

export const beforeLoginUpcomingFixtureRequest = (data, callback) => {
    return (dispatch) => {
        dispatch({ type: beforeLoginConstants.BEFORE_LOGIN_UPCOMING_FIXTURE_REQUEST, data: {} });
        beforeLoginUpcomingFixture(data).then((response) => {
            callback(response.data);
            dispatch({ type: beforeLoginConstants.BEFORE_LOGIN_UPCOMING_FIXTURE_SUCCESS, data: response.data });
        }).catch((error) => {
            dispatch({ type: beforeLoginConstants.FAILED_RESPONSE, data: error });
        });
    };
};


export const beforeLogingetMatchDetailRequest = (data, callback) => {
    return (dispatch) => {
        dispatch({ type: beforeLoginConstants.BEFORE_LOGIN_GET_MATCH_DETAIL_REQUEST, data: {} });
        beforeLoginMatchDetail(data).then((response) => {
            callback(response.data);
            dispatch({ type: beforeLoginConstants.BEFORE_LOGIN_GET_MATCH_DETAIL_SUCCESS, data: response.data });
        }).catch((error) => {
            dispatch({ type: beforeLoginConstants.FAILED_RESPONSE, data: error });
        });
    };
};