import { matchConstants } from "../../_constants";
import { getAllSport, getInPlayFixture, getUpcomingFixture, getMatchDetail, getclientAnnouncement, betPlace, getPinnedMatches, getclientBalance, getSportsLeagues, getLeagueEvents, getAwsCasino, getMasterData, getPaymentInfo, depositRequest, getBanners } from "App/_redux/_services";

export const getGlobalAnnouncementRequest = (callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_CLIENT_ANNOUNCEMENT_REQUEST, data: {} });
    getclientAnnouncement().then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_CLIENT_ANNOUNCEMENT_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};
export const getBannersRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_BANNERS_REQUEST, data: {} });
    getBanners(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_BANNERS_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};
export const getCasinoBannersRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_CASINO_BANNERS_REQUEST, data: {} });
    getBanners(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_CASINO_BANNERS_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getS3MasterDataRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_MASTER_DATA_REQUEST, data: {} });
    getAwsCasino(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_MASTER_DATA_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};
export const getMasterDataRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_MASTER_DATA_REQUEST, data: {} });
    getMasterData(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_MASTER_DATA_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const paymentInfoRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_PAYMENT_INFO_REQUEST, data: {} });
    getPaymentInfo(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_PAYMENT_INFO_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};
export const depositPaymentRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_DEPOSIT_PAYMENT_REQUEST, data: {} });
    depositRequest(data).then((response) => {
      callback(response);
      dispatch({ type: matchConstants.GET_DEPOSIT_PAYMENT_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getAllGamesRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_GAMES_LIST_REQUEST, data: {} });
    getAllSport(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_GAMES_LIST_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getSportsLeaguesRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_SPORTS_LEAGUES_REQUEST, data: {} });
    getSportsLeagues(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_SPORTS_LEAGUES_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};
export const getLeagueEventRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_LEAGUE_EVENTS_REQUEST, data: {} });
    getLeagueEvents(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_LEAGUE_EVENTS_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getUserBalanceRequest = (callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_USER_BALANCE_REQUEST, data: {} });
    getclientBalance().then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_USER_BALANCE_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getAllSportRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_ALL_SPORT_REQUEST, data: {} });
    getAllSport(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_ALL_SPORT_SUCCESS, data: response.data });
    }).catch((error) => {
      callback(false);
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getSelectedSportTabRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_SELECTED_SPORT_TAB, data: data });
  };
};
export const getSelectedSportRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_SELECTED_SPORT, data: data });
  };
};

export const getInPlayFixtureRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_INPLAAY_FIXTURE_REQUEST, data: {} });
    getInPlayFixture(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_INPLAAY_FIXTURE_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getUpcomingFixtureRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_UPCOMING_FIXTURE_REQUEST, data: {} });
    getUpcomingFixture(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_UPCOMING_FIXTURE_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};
export const getPinnedMatchesRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_PINNED_MATCHES_REQUEST, data: {} });
    getPinnedMatches(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_PINNED_MATCHES_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getMatchDetailRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_MATCH_DETAIL_REQUEST, data: {} });
    getMatchDetail(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_MATCH_DETAIL_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getclientAnnouncementRequest = () => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_CLIENT_ANNU_REQUEST, data: {} });
    getclientAnnouncement().then((response) => {
      dispatch({ type: matchConstants.GET_CLIENT_ANNU_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const setBetRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.SET_BET_SUCCESS, data });
  };
};

export const betPlaceAction = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_CLIENT_ANNU_REQUEST, data: {} });
    betPlace(data).then((response) => {
      callback({ status: true, message: "Bet Placed Successfully" });
      dispatch({ type: matchConstants.GET_CLIENT_ANNU_SUCCESS, data: response.data });
    }).catch((error) => {
      let msg = '';
      if (error?.response?.data?.error) {
        msg = error?.response?.data?.error;
      } else {
        msg = "Bet Placed Failed";
      }
      // toast.error(msg);

      callback({ status: false, message: msg });
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};



