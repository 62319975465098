import React, { useEffect, useState, Fragment } from "react";
import { constant } from "App/helper/constant";
import { getMatchDetailRequest, updateUserBalance } from "App/_redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LoginModal, PlaceBetMessageModal, SessionBookModal } from "App/modals"
import WebSocketContextComponent from "App/context/webSocketContext";
import Swal from 'sweetalert2'
import { FaRegClock } from "react-icons/fa";

import MarketDetail from "./MarketDetail";
// import MarketBet from "./MarketBet";
// import NumericCode from "./NumericKeyboard";
// import PlaceMarketBet from "./PlaceMarketBet";
// import PlaceSessionBet from "./PlaceSessionBet";
// import SessionBet from "./SessionBet";
import SessionDetail from "./SessionDetail";

import {
    getUserbalance,
    getMarketSessionBet,
    getclientBalance,
    betPlace,
    getSessionBook,
} from "App/_redux/_services";

import { Utils } from "App/utils"

import { BetslipOpenBet, DeclaredMarkets, Timer } from "App/components"

import { commentaryType, differentFancyList } from "App/utils";
import { toast } from "react-hot-toast";
import Iframe from "react-iframe";
import INFO_img from "../../assets/img/info.svg";

const MatchDetail = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { event_id } = useParams();

    const [showModal, setShowModal] = React.useState(false);
    const { currentUser } = useSelector((state) => state.auth);
    const { isLoader, GlobalAnnouncement } = useSelector((state) => state.match);
    const [tempWinloss, setTempWinloss] = useState(null)

    const [state, setAllStateValue] = useState({
        betAmount: 0,
        redirect: "",
        width: window.innerWidth,
        height: window.innerHeight,
        bookmakerType: 0,
        bettingType: 0,
        price: 0,
        displayPrice: 0,
        marketId: "",
        selectionId: "",
        toggleBetForm: false,
        name: "",
        marketBetData: "",
        sessionBetData: "",
        eventId: "",
        shown: false,
        showAlertDialog: false,
        alertType: "",
        alertMessage: "",
        commentaryData: "",
        timer: 0,
        betPlacedClick: false,
        event_id: event_id,
        openBetSlipid: '',
        PlaceBetMessageModalShow: false,
        placeBetMessage: null,
        betErro: false
    });
    const [matchDetail, setMatchDetails] = useState({});
    const [allActiveFancySessions, setAllActiveFancySessions] = useState([]);
    const [allMarketIds, setAllMarketIds] = useState([]);
    const [socket, setSocket] = useState(false);
    const [toggleRoom, setToggleRoom] = useState(false);
    const [oddsStack, setOddsStack] = useState([]);
    const [odd_diff, setOddDiff] = useState("");
    const [openSessionModal, setOpenSessionModal] = useState(false);
    const [callBalanceAPI, setCallBalanceAPI] = useState(false);
    const [scorURL, setScorURL] = useState("score1");
    const [tvURL, setTvURL] = useState(false);
    const [open, setOpen] = useState(false)
    const [, setOpenSettingModal] = useState()

    const [Events, setEvent] = useState([])

    const [scrollIframe, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 260) {
                setScroll(true);
            } else if (window.scrollY < 260) {
                setScroll(false);
            }

        });
    }, []);


    // useEffect(() => {
    //     const param = {
    //         "event_id": state.event_id
    //     }
    //     if (currentUser.username) {
    //         if (state) {
    //             dispatch(getMatchDetailRequest(param, (res) => {
    //                 if (res) {
    //                     setEvent(res)
    //                 }
    //             }));

    //         } else {
    //             navigate(-1);
    //         }
    //     }
    //     else {
    //         // if (state) {
    //         //     dispatch(getMatchDetailRequest(param, (res) => { if (res) setEvent(res) }));
    //         // } else {
    //         //     navigate(-1);
    //         // }
    //     }


    // }, [state, dispatch, navigate, currentUser]);


    useEffect(() => {
        loadData();
        return () => {
            window.removeEventListener("resize", updateDimensions);
            if (state.socketValue) {
                state.socketValue.closeRoom(getAllMarketIds());
                setMatchDetails({});
            }
        };
    }, []);


    // useEffect(() => {
    //     if (matchDetail && matchDetail.event && matchDetail.event.sport_id && matchDetail.event.sport_id != undefined && matchDetail.event.sport_id != 4) {
    //         setScorURL('score2')
    //     }
    // }, [matchDetail]);


    useEffect(() => {
        if (state.shown) {
            scrollToView();
        }
    });

    useEffect(() => {
        if (state.eventId && params.event_id !== state.eventId) {
            setState({ eventId: params.event_id });
            window.location.reload();
        }
    }, [params.event_id]);

    useEffect(() => {
        getclientBalanceData();
    }, [callBalanceAPI]);

    useEffect(() => {
        if (state.shown) {
            scrollToView();
        }
    });

    const getclientBalanceData = async () => {
        getclientBalance().then((response) => {
            if (response && response.data) {
                let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                currentUser.balance = response.data.balance;
                currentUser.exposure = response.data.exposure;
                currentUser.bonus_balance = response.data.bonus_balance;
                currentUser.withdrawal_balance = response.data.withdrawal_balance;
                localStorage.setItem("currentUser", JSON.stringify(currentUser));
                setOddsStack(
                    response.data.odds_stack ? response.data.odds_stack.split(",") : []
                );
                setOddDiff(response?.data?.odd_diff ? response.data.odd_diff : "");
            }
        });
    };

    const handleCommentaryStatusModal = (commentary) => {
        let commentaryMessage = "";
        switch ("" + matchDetail?.event?.commentary_button_id) {
            case "1":
                commentaryMessage = commentaryType[1].message;
                break;
            case commentary.commentary_button_id:
                commentaryMessage =
                    commentaryType[commentary.commentary_button_id].message;
                break;
        }
        if (commentaryMessage) {
            setState({
                alertType: "error",
                showAlertDialog: true,
                alertMessage: commentaryMessage,
            });
            setTimeout(() => {
                setState({ showAlertDialog: false, alertMessage: "" });
            }, 2000);
        }

        return !!commentaryMessage;
    };

    useEffect(() => {
        if (!state.toggleBetForm) {
            clearValues();
        }
    }, [state.toggleBetForm]);

    const setState = (obj, callback) => {
        setAllStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const clearValues = () => {
        setState({
            betAmount: 0,
            bookmakerType: 0,
            bettingType: 0,
            price: 0,
            displayPrice: 0,
            marketId: "",
            selectionId: "",
            name: "",
        });
    };

    const updateDimensions = () => {
        setState({ width: window.innerWidth, height: window.innerHeight });
    };

    const loadData = () => {
        window.addEventListener("resize", updateDimensions);
        fetchMatchDetailData();
        // fetchMarketBet();
        // fetchSessionBet();
        let shouldShowTV = sessionStorage.getItem("show_tv");
        if (shouldShowTV == 1) {
            setState({ shown: true });
        }
    };

    const fetchMatchDetailData = () => {
        const { event_id } = params;
        dispatch(
            getMatchDetailRequest({ event_id }, (res) => {
                matchDetailParse(res);
                setState({ eventId: event_id });
            })
        );
    };

    const matchDetailParse = (data) => {
        let finalData = [];
        finalData = data.market;
        data.market.map((market, markey) => {
            market.runners.map((run, key) => {
                market.runner_odds.find((odd) => {
                    if (run.selectionId === odd.selectionId) {
                        run["runner_odd"] = odd;
                        finalData[markey].runners[key] = run;
                    }
                });
            });
        });
        if (data?.event?.sport_id == 1) {
            let allMarket = finalData;
            for (let i = 0; i < allMarket.length; i++) {
                const marketRunner = allMarket[i].runners;
                const updatedRunner = [];
                let drawRunner = null;
                for (let j = 0; j < marketRunner.length; j++) {
                    if (marketRunner[j].runnerName == "The Draw") {
                        drawRunner = marketRunner[j];
                    } else {
                        updatedRunner.push(marketRunner[j]);
                    }
                }
                if (drawRunner) {
                    updatedRunner.push(drawRunner);
                }
                allMarket[i].runners = updatedRunner;
            }
            finalData = allMarket;
        }
        data.market = finalData;
        const liveData = data.event.score ? JSON.parse(data.event.score) : "";
        const parseData = parseLiveScore(liveData, data); //ig
        data.live_msg = parseData.liveScoreMsg;
        data.live_score1 = parseData.live_score1;
        data.live_score2 = parseData.live_score2;
        handleAllactiveFancy(state.allActiveFancySessions, data);
        setToggleRoom((toggle) => !toggle);
    };

    const getAllMarketIds = ({ market = [], fancy = [] }) => {
        const sessions = fancy;
        const ids = [];
        if (market?.length > 0) {
            for (let i = 0; i < market.length; i++) {
                ids.push(market[i].market_id);
            }
        }
        if (sessions?.length > 0) {
            for (let i = 0; i < sessions.length; i++) {
                ids.push("" + sessions[i].session_id);
            }
        }
        setAllMarketIds(ids);
    };

    const parseLiveScore = (liveData, isMatchEqualEvent) => {
        //
        let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };

        if (liveData) {
            let liveScoreMsg = liveData?.data?.msg;
            let liveScore1 = `${liveData.data?.teams?.map((i) => i?.team_name)[0]} - ${liveData.data?.teams?.map((i) => i?.score)[0]} Ovs`;
            let liveScore2 = `${liveData.data?.teams?.map((i) => i?.team_name)[1]} - ${liveData.data?.teams?.map((i) => i?.score)[1]} Ovs`;
            let liveCrrScore = liveData?.data?.msg;

            let rr = "";
            if (!isMatchEqualEvent && liveData.data?.currentRunRate) {
                rr = " CRR: " + liveData.data?.currentRunRate;
                liveCrrScore = rr;
            }
            if (!isMatchEqualEvent && liveData.data?.requireRunRate) {
                liveScoreMsg = `${liveData.data?.teams?.map((i) => i?.team_name)[0]}  needed  ${liveData.data?.runNeeded}  runs from ${liveData.data?.ballsRemaining} balls`
                rr = `${liveScoreMsg}` + " RRR: " + liveData.data?.requireRunRate;
                liveCrrScore = rr;
            }


            let sixBall = liveData?.data?.last24balls;
            let players = liveData?.data?.currentPlayersScore;

            finalData = {
                live_score1: liveScore1,
                live_score2: liveScore2,
                liveScoreMsg: liveScoreMsg,
                sixBall: sixBall,
                players: players,
                liveCrrScore: liveCrrScore
            };
        }


        return finalData;
    };

    const updateSocketResponse = (data) => {
        const { event_id } = params;
        if (data && data.settlementDone) {
            updateSettleMent(data);
            return;
        }
        if (data && data.reloadPage) {
            setToggleRoom((toggle) => !toggle);
            return;
        }
        if (
            data &&
            data?.allActiveFancy &&
            data?.allActiveFancy?.event_id == event_id
        ) {
            handleAllactiveFancy(data.allActiveFancy.session_ids, matchDetail);
            return;
        }
        if (data?.addUpdateFancy?.event_id == event_id) {
            fetchMatchDetailData();
            return;
        }
        setMatchDetails((socketData) => {
            switch (true) {
                case !!data?.updateMarketOdds?.odds &&
                    data?.updateMarketOdds?.event_id == event_id:
                    const updatedData = data.updateMarketOdds;
                    try {
                        let finalData = [];
                        finalData = socketData.market;
                        socketData.market.map((market, markey) => {
                            if (updatedData.market_id == market.market_id) {
                                market.match_status = updatedData.status;
                                market.runner_odds = updatedData.odds;
                                market.runners.map((run, key) => {
                                    updatedData.odds.find((odd) => {
                                        if (run.selectionId == odd.selectionId) {
                                            run["runner_odd"] = odd;
                                            finalData[markey].runners[key] = run;
                                        }
                                    });
                                });
                            }
                        });
                        return { ...socketData, market: finalData };
                    } catch (error) {
                        return socketData;
                    }
                case !!data?.updateFancyOdds &&
                    data?.updateFancyOdds?.event_id == event_id:
                    const updatedFancy = data.updateFancyOdds;
                    let finalData = [...socketData.fancy];
                    socketData.fancy.map((fancyItem, fancyKey) => {
                        if (
                            fancyItem.session_id == updatedFancy.market_id &&
                            fancyItem.event_id == updatedFancy.event_id
                        ) {
                            fancyItem.lay_price1 = updatedFancy.lay_price1;
                            fancyItem.lay_size1 = updatedFancy.lay_size1;
                            fancyItem.back_price1 = updatedFancy.back_price1;
                            fancyItem.back_size1 = updatedFancy.back_size1;
                            fancyItem.game_status = updatedFancy.game_status;
                            finalData[fancyKey] = fancyItem;
                        }
                    });
                    return { ...socketData, fancy: finalData };
                case data?.updateScore?.event_id == socketData?.event?.event_id:
                    const liveData = data.updateScore.score
                        ? JSON.parse(data.updateScore.score)
                        : "";
                    const parseData = parseLiveScore(liveData, data?.updateScore?.match_id == data?.updateScore?.event_id);
                    return {
                        ...socketData,
                        live_msg: parseData.liveScoreMsg,
                        live_score1: parseData.live_score1,
                        live_score2: parseData.live_score2,
                        SixBall: parseData.sixBall,
                        players: parseData.players,
                        RunRate: parseData.liveCrrScore
                    };

                case data?.updateCommentary?.event_id == event_id:
                    return {
                        ...socketData,
                        event: {
                            ...socketData.event,
                            button_label: data.updateCommentary.button_label,
                            commentary_button_id: data.updateCommentary.commentary_button_id,
                            btn_color: data.updateCommentary.btn_color,
                        },
                    };
                case data?.updateEventAnnouncement?.event_id == event_id:
                    return {
                        ...socketData,
                        event: {
                            ...socketData.event,
                            announcement: data.updateEventAnnouncement.announcement,
                        },
                    };

                case data?.updateTVURL?.event_id == event_id:
                    return {
                        ...socketData,
                        event: {
                            ...socketData.event,
                            live_tv_url: data.updateTVURL.live_tv_url,
                        },
                    };
                case data?.updateFancyBetLock?.event_id == event_id:
                    let fancy = socketData.fancy;
                    for (let i = 0; i < fancy.length; i++) {
                        if (fancy[i].session_id == data.updateFancyBetLock.session_id) {
                            fancy[i].bet_lock = data.updateFancyBetLock.bet_lock;
                        }
                    }
                    return {
                        ...socketData,
                        fancy,
                    };
                case data?.updateFancySectionLock?.event_id == event_id:
                    let fancy1 = socketData.fancy;
                    for (let i = 0; i < fancy1.length; i++) {
                        if (
                            data.updateFancySectionLock.session_type.includes(
                                fancy1[i].session_type
                            )
                        ) {
                            fancy1[i].bet_lock = data.updateFancySectionLock.bet_lock;
                        }
                    }
                    return {
                        ...socketData,
                        fancy1,
                    };
                case data?.updateLockMarketBetting?.event_id == event_id:
                    let market = socketData.market;
                    for (let i = 0; i < market.length; i++) {
                        if (market[i].market_id == data.updateLockMarketBetting.market_id) {
                            let runner = market[i].runners;
                            for (let j = 0; j < runner.length; j++) {
                                if (
                                    data.updateLockMarketBetting.selection_id === "" ||
                                    runner[j].selectionId ==
                                    data.updateLockMarketBetting.selection_id
                                ) {
                                    runner[j].lock_team_betting =
                                        data.updateLockMarketBetting.lock_status;
                                }
                            }
                            market[i].runners = runner;
                        }
                    }
                    return {
                        ...socketData,
                        market,
                    };
                case data?.marketAnnouncement?.event_id == event_id:

                    // let fancy1 = socketData.fancy;
                    // for (let i = 0; i < fancy1.length; i++) {
                    //   if (
                    //     data.updateFancySectionLock.session_type.includes(
                    //       fancy1[i].session_type
                    //     )
                    //   ) {
                    //     fancy1[i].bet_lock = data.updateFancySectionLock.bet_lock;
                    //   }
                    // }
                    return {
                        ...socketData,
                        // fancy1,
                    };
                default:
                    return socketData;
            }
        });
    };

    const handleAllactiveFancy = (
        allActiveFancy = allActiveFancySessions,
        matchData
    ) => {
        let fancyData = matchData?.fancy.filter((itemFacny) =>
            allActiveFancy.length
                ? allActiveFancy.includes(JSON.stringify(itemFacny.session_id))
                : true
        );
        matchData.fancy = fancyData;
        setMatchDetails(matchData);
        getAllMarketIds(matchData);
        setAllActiveFancySessions(allActiveFancy);
    };

    const updateSettleMent = (data) => {
        setMatchDetails((socketData) => {
            if (socketData?.event?.event_id == data?.settlementDone.event_id) {
                if (data.settlementDone.bookmaker_type == 3) {
                    let allFancy = socketData.fancy;

                    let iIndex = -1;
                    for (let i = 0; i < allFancy.length; i++) {
                        if (allFancy[i].session_id == data.settlementDone.session_id) {
                            iIndex = i;
                            break;
                        }
                    }
                    if (iIndex > -1) {
                        allFancy.splice(iIndex, 1);
                        return { ...socketData, fancy: allFancy };
                    }
                }
                if (data.settlementDone.bookmaker_type == 2) {
                    let allMarket = socketData.market;
                    let iIndex = -1;
                    for (let i = 0; i < allMarket.length; i++) {
                        if (allMarket[i].market_id == data.settlementDone.market_id) {
                            iIndex = i;
                            break;
                        }
                    }
                    if (iIndex > -1) {
                        allMarket.splice(iIndex, 1);
                        return { ...socketData, market: allMarket };
                    }
                }
                return { ...socketData };
            } else {
                return { ...socketData };
            }
        });
    };

    const fetchMarketBet = () => {
        const { event_id } = params;
        let sendData = {};
        sendData.items_perpage = 100;
        sendData.current_page = 1;
        sendData.filter = 1;
        sendData.event_id = event_id;

        getMarketSessionBet(sendData)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.open_bets &&
                    response.data.open_bets.length
                ) {
                    setState({ marketBetData: response.data.open_bets });
                } else {
                    setState({ marketBetData: [] });
                }
            })
            .catch((error) => {
                setState({ marketBetData: [] });
            });
    };

    const fetchSessionBet = () => {
        const { event_id } = params;
        let sendData = {};
        sendData.items_perpage = 100;
        sendData.current_page = 1;
        sendData.filter = 3;
        sendData.event_id = event_id;

        getMarketSessionBet(sendData)
            .then((response) => {
                if (
                    response &&
                    response.data &&
                    response.data.open_bets &&
                    response.data.open_bets.length
                ) {
                    setState({ sessionBetData: response.data.open_bets });
                } else {
                    setState({ sessionBetData: [] });
                }
            })
            .catch((error) => {
                setState({ sessionBetData: [] });
            });
    };

    const setbetAmount = (betAmount) => {
        setState({ betAmount });

        //reset timer here
        setState({ timer: 0 });
    };

    const setBookmakerType = (bookmakerType) => {
        setState({ bookmakerType });
    };

    const setBettingType = (bettingType) => {
        setState({ bettingType });
    };

    const setPrice = (price) => {
        setState({ price });
    };

    const setDispalyPrice = (displayPrice) => {
        setState({ displayPrice });
    };

    const setMarketId = (marketId) => {
        setState({ marketId });
    };

    const setSelectionId = (selectionId) => {
        setState({ selectionId });
    };

    const setName = (name) => {
        setState({ name });
    };

    const setTeamName = (teamName) => {
        setState({ teamName });
    };

    const setBatType = (batType) => {
        setState({ batType });
    };

    const setToggleBetForm = (val) => {
        setState({ toggleBetForm: val });

        //start timer here
        if (val) {
            updateTimer();
        } else {
            setState({ timer: 0, betPlacedClick: true });
        }
    };

    const donePressed = () => {
        setState({ timer: 0, betPlacedClick: true });
    };

    const updateTimer = () => {
        if (state.timer == 10) {
            setState({ timer: 0, toggleBetForm: false });
        } else {
            setTimeout(() => {
                let time = state.timer;

                time += 1;
                setState({ timer: time }, () => {
                    if (!state.betPlacedClick) {
                        updateTimer();
                    }
                });
            }, 1000);
        }
    };
    const setSelectedData = (selectedData) => {
        setState({ selectedData });
    };

    const getMarketList = (type, allMarket = []) => {
        let filteredList = [];
        for (let i = 0; i < allMarket.length; i++) {
            if (type == 2) {
                if (
                    allMarket[i].market_type == 4 ||
                    allMarket[i].market_type == 6 ||
                    allMarket[i].market_type == 7
                ) {
                    filteredList.push(allMarket[i]);
                }
            } else if (type == 1) {
                if (
                    allMarket[i].market_type != 4 &&
                    allMarket[i].market_type != 6 &&
                    allMarket[i].market_type != 7
                ) {
                    filteredList.push(allMarket[i]);
                }
            }
        }
        if (filteredList?.length) {
            const tieMatchInd = filteredList?.findIndex(
                (item) => matchDetail?.event?.sport_id == 4 && item?.market_type == 4
            );
            if (tieMatchInd >= 0) {
                filteredList.push(...filteredList.splice(tieMatchInd, 1));
            }
        }
        return filteredList;
    };

    const placeBetRequest = (callback = {}) => {
        let sendData = {};
        sendData.bookmaker_type = state.bookmakerType;
        sendData.event_id = matchDetail?.event?.event_id
            ? matchDetail?.event.event_id
            : "";
        sendData.market_id = state.marketId;
        sendData.stake = parseFloat(state.betAmount);
        sendData.selection_id = state.selectionId;
        sendData.price = state.price;
        sendData.betting_type = state.bettingType;
        betPlace(sendData)
            .then((resp) => {

                if (resp.error == "" && resp.message) {

                    Swal.fire({
                        // position: "top-end",
                        icon: "success",
                        title: 'Success',
                        text: resp.message,
                        // showConfirmButton: false,
                        // timer: 50000,
                        confirmButtonText: 'Ok',
                        confirmButtonColor: "#28a745",
                    });
                    // setState({ PlaceBetMessageModalShow: true, placeBetMessage: resp.message, betErro: false })
                } else if (resp.message == "" && resp.error) {
                    // setState({ PlaceBetMessageModalShow: true, placeBetMessage: resp.error, betErro: true })
                    Swal.fire({
                        title: 'Error!',
                        text: resp.error,
                        icon: 'error',
                        confirmButtonText: 'Close',
                        confirmButtonColor: "#d33",
                    })
                }

                // if (resp.error == "" && resp.message) {
                //     toast.success(resp.message)
                // }
                if (resp.error == "" && resp.data) {
                    setMatchDetails((matchData) => {
                        let marketArray = [...matchData?.market].map((item) => {
                            if (item?.market_id == state.marketId) {
                                const runners = item?.runners.map((run) => ({
                                    ...run,
                                    winloss: resp?.data?.win_loss[run?.selectionId],
                                }));
                                return { ...item, runners };
                            } else {
                                return item;
                            }
                        });
                        matchData["market"] = marketArray;
                        return matchData;
                    });
                }
                setState({ toggleBetForm: false });

                if (resp && resp.error === "") {
                    setState({
                        alertType: "success",
                        showAlertDialog: true,
                        alertMessage: resp.message,
                    });
                    // fetchMarketBet();
                    // fetchSessionBet();

                    getUserbalance()
                        .then((res) => {
                            let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                            currentUser.balance = res.data.balance;
                            currentUser.exposure = res.data.exposure;
                            currentUser.bonus_balance = res.data.bonus_balance;
                            currentUser.withdrawal_balance = res.data.withdrawal_balance;
                            localStorage.setItem("currentUser", JSON.stringify(currentUser));
                            dispatch(updateUserBalance(res.data))

                            // setTimeout(() => {
                            //     window.location.reload();
                            // }, 1000);
                        })
                        .catch((error) => {
                            //  callback()
                        });

                    clearValues();
                } else {
                    clearValues();
                    setState({
                        alertType: "error",
                        showAlertDialog: true,
                        alertMessage: resp.message,
                    });
                    setTimeout(() => {
                        setState({ showAlertDialog: false });
                        // window.location.reload();
                    }, 1000);
                }

            })
            .catch((error) => {
                setState({ toggleBetForm: false });
                clearValues();
                setState({
                    alertType: "error",
                    showAlertDialog: true,
                    alertMessage: error?.response?.data?.error
                        ? error?.response?.data?.error
                        : "Bet Placed Failed",
                });
                setTimeout(() => {
                    setState({ showAlertDialog: false });
                    // window.location.reload();
                }, 1000);
            });
    };
    const handleClick = () => {
        // need to check beofre push
        setState({ shown: true });
        sessionStorage.setItem("show_tv", 1);
    };

    const scrollToBottom = (elementId, clickOffset) => {
        if (window.screen.width > 767) {
            // const totalBat = state.marketBetData.length + state.sessionBetData.length;
            setTimeout(() => {
                const offset1 = document.getElementById(elementId)?.offsetTop;
                const offset = document.getElementById("place-market-bet")?.offsetTop;
                const topValue = offset - (offset1 + 400);
                window.scrollTo({
                    top: clickOffset
                        ? offset - clickOffset - 190
                        : topValue < 500
                            ? 500
                            : topValue,
                    behavior: "smooth",
                });
            }, 300);
        }

        return true;
    };

    const scrollToView = () => {
        const ptDiv = document.getElementById("is_append_video");
        const myDiv = document.getElementById("append_video");
        const oddDiv = document.getElementById("content_matches_wrapper");
        window.addEventListener("scroll", function () {
            const rect = ptDiv.getBoundingClientRect();
            if (rect.top <= 50) {
                myDiv.classList.add("append_video");
                oddDiv.style.marginTop = myDiv.getBoundingClientRect().height + "px";
            } else {
                myDiv.classList.remove("append_video");
                oddDiv.style.removeProperty("margin-top");
            }
        });
    };

    const setStateOfParent = (condition) => {
        setState({ shown: condition });
        sessionStorage.setItem("show_tv", condition ? 1 : 0);
    };

    const resetViewAfterYellowBtn = () => {
        let data = matchDetail;
        data.event.btn_color = "Green";
        data.event.bet_close_time = "";
        data.event.button_label = "Open All";
        data.event.commentary_button_id = 2;
        setState({ matchData: data });
    };

    const updateScoketValue = (socketValue) => {
        setState({ socketValue });
    };

    const showSessionBook = async (id) => {
        const payload = {
            event_id: params?.event_id,
            session_id: id,
        };
        const res = await getSessionBook(payload);
        if (res?.data) {
            setOpenSessionModal(res?.data);
        }
    };

    const openBetSlipMarketId = (openBetSlipid) => {
        setState({ openBetSlipid })
    }


    const switchScoreCard = (url) => {
        setScorURL(url)
    }

    const modalHide = () => {
        setState({ PlaceBetMessageModalShow: false })
    }

    const setWinlossState = (obj, callback) => {
        setTempWinloss((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const setWinloss = (winloss) => {
        setWinlossState({ winloss })
    }

    const setRunnerWin = (run) => {
        setWinlossState(run)
    }

    const PlaceBetMessageModalProps = {
        showModal: state.PlaceBetMessageModalShow,
        placeBetMessage: state.placeBetMessage,
        betErro: state.betErro,
        toggle: modalHide
    }

    const {
        betAmount,
        price,
        displayPrice,
        toggleBetForm,
        name,
        marketBetData,
        sessionBetData,
        refreshPrice,
        selectedData,
        PlaceBetMessageModalShow,
    } = state;

    const { event } = matchDetail;
    var { betPriceDetail } = props;

    return (
        <div className={`mainContainer_layOut`}>
            {/* import tabs here */}

            {allMarketIds.length ? (
                <WebSocketContextComponent
                    marketList={allMarketIds}
                    handleSocketData={(e) => {
                        updateSocketResponse(e);
                    }}
                    closeAllRoom={toggleRoom}
                    setSocket={setSocket}
                    socket={socket}
                    event_id={event_id}
                />
            ) : (
                ""
            )}

            <div >
                <div className="mainFlx_bx">
                    <div className="leftContent">
                        <Fragment>

                            <div className="flex anvMarquee  h-[28px] md:h-7 items-center md:m-[0px_0px_7px] md:rounded overflow-hidden">
                                <img className="h-4 px-1" src={INFO_img} />
                                <marquee
                                    className="text-[12px] flex items-center font-semibold "
                                    behavior=""
                                    direction=""
                                >
                                    {GlobalAnnouncement != null ? GlobalAnnouncement.client_msg : `Welcome to gamebey !!`}
                                </marquee>
                            </div>

                            {/* Score board */}
                            {matchDetail && matchDetail?.event && matchDetail?.event.open_date && matchDetail?.event?.in_play == 0 &&
                                <div className="upcoming_title_outer">
                                    <div className="upcoming_title">
                                        <div className="timer_div">
                                            <div className="flex justify-center items-center py-4 absolute top-[-78px]">
                                                <Timer EventDetails={matchDetail?.event} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="date_time_">
                                        {Utils.gameDate(matchDetail?.event?.open_date)} {" "}{Utils.getTime(matchDetail?.event?.open_date)}
                                    </div>
                                    <div className="vs_teams">
                                        {matchDetail.event.event_name}
                                    </div>
                                </div>
                            }

                            {matchDetail && matchDetail?.event && matchDetail?.event?.in_play == 1 &&
                                <Fragment>
                                    <div className="block">
                                        <div className="bg-active text-active-foreground flex justify-between font-semibold p-2 text-[12px] text-md items-center ">
                                            <div className="text-ellipsis w-full overflow-hidden text-nowrap ">{matchDetail?.event?.league_name} {">"} {matchDetail?.event?.event_name}</div>
                                            <div className="flex items-center text-nowrap">
                                                {/* <FaRegClock className="mr-1" />{Utils.getDateAndTime("MMM DD, YYYY", matchDetail?.event?.open_date)} {"|"} {Utils.getTime(matchDetail?.event?.open_date)} */}
                                                {(Utils.gameDate(matchDetail?.event?.open_date) === "Live" || matchDetail?.event?.in_play) ?
                                                    <div className="">
                                                        <h4 className="liveTxt text-active-foreground">LIVE</h4>
                                                        <div class=" animated_line" >
                                                            <div class="line_animation" ></div>
                                                        </div>
                                                    </div>

                                                    : Utils.gameDate(matchDetail?.event?.open_date)}
                                                {Utils.gameDate(matchDetail?.event?.open_date) !== "Live" && <div className="">
                                                    <span className="text-[10px] font-medium ml-1">{Utils.getTime(matchDetail?.event?.open_date)}</span>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                    {matchDetail?.event?.sport_id != 444 && <div className="scoreBoard_main pb-0 border-none rounded-none mt-1">
                                        <div className="flex anvScor_bord justify-between p-[5px_11px]">
                                            <div className="flex max-w-56 w-full">
                                                {
                                                    // matchDetail && matchDetail.event && matchDetail.event.sport_id
                                                    //     && matchDetail.event.sport_id != undefined
                                                    //     && matchDetail.event.sport_id == 4
                                                    //     && 
                                                    <button className={"scoreBtn-primary2 " + (scorURL === "score1" && "scorActive")}
                                                        onClick={() => switchScoreCard(scorURL === "score1" ? null : "score1")}>Score</button>}
                                                {/* {matchDetail && matchDetail.event && matchDetail.event.sport_id
                                                    && matchDetail.event.sport_id != undefined
                                                    && matchDetail.event.sport_id != 4 &&
                                                    <button className={"scoreBtn-primary2 " + (scorURL === "score2" && "scorActive")}
                                                        onClick={() => switchScoreCard(scorURL === "score2" ? null : "score2")}>Score {matchDetail && matchDetail.event && matchDetail.event.sport_id && matchDetail.event.sport_id != undefined && matchDetail.event.sport_id == 4 && "2"}</button>} */}
                                            </div>

                                            <div className="flex items-center gap-2 lg:hidden">
                                                <p className="text-sm  font-medium">Live TV</p>

                                                <div>
                                                    <input type="checkbox" className='liveTV_toggle'

                                                        id="liveTV-toggle"
                                                    />
                                                    <label for="liveTV-toggle" className='liveTV_lable' onClick={() => setTvURL(!tvURL)}>
                                                        <span className="spanOn">On</span>
                                                        <span className="spanOff">Off</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <button className={"scoreBtn-primary2 justify-end " + (tvURL && "scorActive")} onClick={() => setTvURL(!tvURL)}>Live TV</button> */}
                                        </div>

                                        <div className="bg-secondary text-secondary-foreground flex flex-wrap gap-x-[22px] gap-y-[8px] w-full p-[0px_8px_11px]">
                                            {scorURL === "score1" ?
                                                <Fragment>
                                                    {matchDetail?.event?.score_url ? <iframe className="w-full h-[10rem]" src={matchDetail?.event?.score_url} frameborder="0" ></iframe> : <p className="w-full text-center text-color">No Score available</p>}
                                                </Fragment>

                                                : scorURL === "score2" ?
                                                    <Fragment>
                                                        {matchDetail?.event?.score_widget ? <iframe className="w-full h-[10rem]" src={matchDetail?.event?.score_widget} frameborder="0"></iframe> : <p className="w-full text-center text-color">No Score available</p>}
                                                    </Fragment>

                                                    : null}
                                            <div className={"lg:hidden w-full" + (scrollIframe ? " sticy_tv" : "")}>
                                                {tvURL && (
                                                    matchDetail?.event?.live_tv_url !== "" ?
                                                        <>
                                                            {/* <iframe className="w-full h-52 lg:h-72" src={matchDetail?.event?.live_tv_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                                            <Iframe url="https://www.sdrive.app/embed/1ptBQD"
                                                                className="w-full h-52 lg:h-72" src={matchDetail?.event?.live_tv_url}
                                                                display="block"
                                                                position="relative" allowfullscreen={true} />
                                                        </>
                                                        : <p className="w-full text-center text-color">No streaming available</p>)
                                                }
                                            </div>
                                        </div>
                                    </div>}
                                </Fragment>
                            }
                            {/* Score board */}


                            {matchDetail?.event?.upline_hide_event === 1 ? (
                                <div className="row matchBox-Row">
                                    <h3
                                        className="in-play-row-left"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Match hide, please contact to uplines.
                                    </h3>
                                </div>
                            ) : (
                                <>
                                    <MarketDetail
                                        event={matchDetail?.event}
                                        marketList={getMarketList(1, matchDetail?.market)}
                                        setBookmakerType={setBookmakerType}
                                        setBettingType={setBettingType}
                                        setPrice={setPrice}
                                        setDispalyPrice={setDispalyPrice}
                                        setMarketId={setMarketId}
                                        setSelectionId={setSelectionId}
                                        setToggleBetForm={setToggleBetForm}
                                        setName={setName}
                                        setTeamName={setTeamName}
                                        setBatType={setBatType}
                                        scrollToBottom={scrollToBottom}
                                        // websocketValue={value}
                                        setSelectedData={setSelectedData}
                                        resetViewAfterYellowBtn={resetViewAfterYellowBtn}
                                        reloadChildView={state.reloadChildView}
                                        // manageSocketValue={updateScoketValue}
                                        handleCommentaryStatusModal={handleCommentaryStatusModal}
                                        //   fetchMatchDetailData={fetchMatchDetailData}

                                        openBetSlipMarketId={openBetSlipMarketId}
                                        openBetSlipid={state.openBetSlipid}
                                        toggleBetForm={toggleBetForm}
                                        betPriceDetail={betPriceDetail}
                                        betAmount={betAmount}
                                        setBetAmount={setbetAmount}
                                        placeBetRequest={placeBetRequest}
                                        onDonePressed={donePressed}
                                        price={price}
                                        displayPrice={displayPrice}
                                        name={name}
                                        teamName={state.teamName}
                                        batType={state.batType}

                                        selectedData={selectedData}
                                        oddsStack={oddsStack}
                                        setOpenSettingModal={(e) => setOpenSettingModal(e)}
                                        marketId={state.marketId}
                                        setRunnerWin={setRunnerWin}
                                        tempWinloss={tempWinloss}
                                        setWinloss={setWinloss}
                                    />

                                    {/* <!-- Session bet board start --> */}

                                    <MarketDetail
                                        matchDetail={matchDetail}
                                        event={matchDetail?.event}
                                        marketList={getMarketList(2, matchDetail?.market)}
                                        setBookmakerType={setBookmakerType}
                                        setBettingType={setBettingType}
                                        setPrice={setPrice}
                                        setDispalyPrice={setDispalyPrice}
                                        setMarketId={setMarketId}
                                        setSelectionId={setSelectionId}
                                        setToggleBetForm={setToggleBetForm}
                                        setName={setName}
                                        setTeamName={setTeamName}
                                        setBatType={setBatType}
                                        scrollToBottom={scrollToBottom}
                                        // websocketValue={value}
                                        setSelectedData={setSelectedData}
                                        resetViewAfterYellowBtn={resetViewAfterYellowBtn}
                                        reloadChildView={state.reloadChildView}
                                        // manageSocketValue={updateScoketValue}
                                        handleCommentaryStatusModal={handleCommentaryStatusModal}
                                        //   fetchMatchDetailData={fetchMatchDetailData}
                                        openBetSlipMarketId={openBetSlipMarketId}
                                        openBetSlipid={state.openBetSlipid}
                                        toggleBetForm={toggleBetForm}
                                        betPriceDetail={betPriceDetail}
                                        betAmount={betAmount}
                                        setBetAmount={setbetAmount}
                                        placeBetRequest={placeBetRequest}
                                        onDonePressed={donePressed}
                                        price={price}
                                        displayPrice={displayPrice}
                                        name={name}
                                        teamName={state.teamName}
                                        batType={state.batType}

                                        selectedData={selectedData}
                                        oddsStack={oddsStack}
                                        setOpenSettingModal={(e) => setOpenSettingModal(e)}
                                        marketId={state.marketId}
                                        setRunnerWin={setRunnerWin}
                                        tempWinloss={tempWinloss}
                                        setWinloss={setWinloss}
                                    />

                                    {/* <!-- Session bet board start --> */}
                                    {matchDetail?.fancy?.length ? (
                                        differentFancyList(
                                            matchDetail?.fancy,
                                            sessionBetData ? sessionBetData : []
                                        ).map((fancy, index) => (
                                            <SessionDetail
                                                index={index}
                                                key={fancy?.session_type}
                                                matchDetailEvents={matchDetail?.event}
                                                sessionList={fancy}
                                                setBookmakerType={setBookmakerType}
                                                setBettingType={setBettingType}
                                                setPrice={setPrice}
                                                setDispalyPrice={setDispalyPrice}
                                                setMarketId={setMarketId}
                                                setSelectionId={setSelectionId}
                                                setToggleBetForm={setToggleBetForm}
                                                setName={setName}
                                                setTeamName={setTeamName}
                                                setBatType={setBatType}
                                                scrollToBottom={scrollToBottom}
                                                // websocketValue={value}
                                                f_exposure={matchDetail?.f_exposure}
                                                handleCommentaryStatusModal={handleCommentaryStatusModal}
                                                setSelectedData={setSelectedData}
                                                showSessionBook={showSessionBook}
                                                //      fetchMatchDetailData={fetchMatchDetailData}
                                                openBetSlipid={state.openBetSlipid}
                                                openBetSlipMarketId={openBetSlipMarketId}
                                                EventDetails={matchDetail}

                                                toggleBetForm={toggleBetForm}
                                                betPriceDetail={betPriceDetail}
                                                betAmount={betAmount}
                                                setBetAmount={setbetAmount}
                                                placeBetRequest={placeBetRequest}
                                                onDonePressed={donePressed}
                                                price={price}
                                                displayPrice={displayPrice}
                                                name={name}
                                                teamName={state.teamName}
                                                batType={state.batType}
                                                event={event}
                                                selectedData={selectedData}
                                                oddsStack={oddsStack}
                                                setOpenSettingModal={(e) => setOpenSettingModal(e)}
                                                width={state.width}
                                            />
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                    <div className="lg:hidden sideMacth_details grid">
                                        <BetslipOpenBet event_id={event_id} />
                                        <DeclaredMarkets event_id={event_id} />
                                    </div>
                                </>
                            )}
                        </Fragment>
                    </div>

                    <div className="rightContent">
                        {/* <div className="block lg:hidden mb-[6px]"> */}
                        {/* ICC Live */}

                        {/* <div className="customGradient4  justify-between font-semibold p-2 rounded-[2px] text-[12px] grid grid-cols-3">
                                <div className="text-ellipsis w-full overflow-hidden text-nowrap col-span-2">{matchDetail?.event?.event_name}</div>
                                <div className="flex items-center text-nowrap justify-end">
                                    
                                    {(Utils.gameDate(matchDetail?.event?.open_date) === "Live" || matchDetail?.event?.in_play) ?
                                        <div className="">
                                            <h4 className="liveTxt text-green-600">LIVE</h4>
                                            <div class=" animated_line" >
                                                <div class="line_animation" ></div>
                                            </div>
                                        </div>

                                        : Utils.gameDate(matchDetail?.event?.open_date)}
                                    {Utils.gameDate(matchDetail?.event?.open_date) !== "Live" && <div className="">
                                        <span className="bg-[#f33f45] text-center rounded-[3px] text-[12px] font-medium px-1 py-[3px] text-white ml-1">{Utils.getTime(matchDetail?.event?.open_date)}</span>
                                    </div>}
                                </div>
                            </div> */}
                        {/* <div className="iccBx customGradient2">
                                <div className="flex gap-x-3 items-center basis-[calc(100%-0px)] sm:basis-[calc(100%-210px)] md:basis-[calc(100%-300px)] text-sm ">
                                    <div className="whitespace-nowrap text-[9px]">
                                        {(Utils.gameDate(matchDetail?.event?.open_date) === "Live" || matchDetail?.event?.in_play) ?
                                            <div className="mb-1">
                                                <h4 className="liveTxt">LIVE</h4>
                                                <div class=" animated_line" >
                                                    <div class="line_animation" ></div>
                                                </div>
                                            </div>

                                            : Utils.gameDate(matchDetail?.event?.open_date)}
                                        {Utils.gameDate(matchDetail?.event?.open_date) !== "Live" && <div className="">
                                            <span className="bg-[#f33f45] text-center rounded-[3px] text-[8px] font-medium px-1 py-[3px] text-white">{Utils.getTime(matchDetail?.event?.open_date)}</span>
                                        </div>}
                                    </div>

                                    <div className="verDivider"></div>

                                    <div className="max-w-[250px] w-full">
                                        <h2 className="live_mediumPara">{matchDetail?.event?.event_name}</h2>
                                        <h2 className="live_mediumPara text-[10px]">{matchDetail?.event?.league_name}</h2>
                                    </div>
                                </div>
                                <div className="basis-[140px] sm:basis-[190px] md:basis-[230px] hidden sm:block">
                                    {matchDetail && matchDetail?.event && matchDetail?.event.open_date && <Timer EventDetails={matchDetail?.event} />}
                                </div>
                            </div> */}
                        {/* ICC Live */}
                        {/* </div> */}

                        <div className="hidden lg:block sideMacth_details detailMatch-side">
                            <div className="mb-[6px] rounded-[3.45px] overflow-hidden">
                                <div className="live_tv min-h-[38px] flex justify-between items-center p-[5px_14px_5px_12px]">
                                    <p className="text-[13.72px] font-semibold ">Live TV</p>

                                    <div>
                                        <input type="checkbox" className='liveTV_toggle'

                                            id="liveTV_web-toggle"
                                        />
                                        <label for="liveTV_web-toggle" className='liveTV_lable' onClick={() => setTvURL(!tvURL)}>
                                            <span className="spanOn">On</span>
                                            <span className="spanOff">Off</span>
                                        </label>
                                    </div>
                                </div>

                                {/* Add TV Here */}

                                {tvURL && (
                                    matchDetail?.event?.live_tv_url !== "" ?
                                        <>
                                            <div className="tvBox1">
                                                {/* <iframe className="w-full h-52 lg:h-72" src={matchDetail?.event?.live_tv_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                                                <Iframe url="https://www.sdrive.app/embed/1ptBQD"
                                                    className="w-full h-52 lg:h-72" src={matchDetail?.event?.live_tv_url}
                                                    display="block"
                                                    position="relative" allowfullscreen={true} />
                                            </div>
                                        </>
                                        : <p className="w-full text-center text-color">No streaming available</p>)
                                }

                                {/* Add TV Here */}
                            </div>


                            <BetslipOpenBet event_id={event_id} />
                            <DeclaredMarkets event_id={event_id} />
                        </div>


                    </div>

                </div>
            </div>

            {openSessionModal && <SessionBookModal toggle={() => setOpenSessionModal(false)} openSessionModal={openSessionModal} />}
            {PlaceBetMessageModalShow && <PlaceBetMessageModal {...PlaceBetMessageModalProps} />}
            <br /><br />
        </div>
    )
}

export default MatchDetail;