import { Navigate } from "react-router-dom";
import { getStorage } from "../helper/storage";
import Layout from "../components/Layout";

export const ProtectRoutes = ({ children, showSidebar }) => {
  const loggedIn = getStorage("currentUser");
  if (!loggedIn) {
    return <Navigate to={"/login"} />;
  }
  return <Layout showSidebar={showSidebar}>{children}</Layout>;
};
