
import { Images } from 'App/constants';
import React from 'react'
import { FcCustomerSupport } from "react-icons/fc";
import { useSelector } from 'react-redux';
import { TbMailFast } from "react-icons/tb";

const Support = () => {
    const { GlobalAnnouncement } = useSelector(state => state.match)
    return (
        <div className='flex w-full justify-center items-center'>
            <div className='my-4 mx-3'>
                <div className='w-full flex justify-between px-4 py-3 bg-sidebar rounded-lg'>
                    <FcCustomerSupport size={40} className='text-2xl' />
                    <div className='ml-2 text-2xl font-bold'>
                        <p>24/7 SUPPORT</p>
                    </div>
                </div>
                <div className='text-center text-xs font-bold mt-1'>
                    <p>Request a call back in your preferred language. English/ Hindi/ Telugu/ Tamil/ Kannada/ Malayalam / Marathi</p>
                </div>

                <div className='text-center my-5 border-b-2 border-primary-foreground rounded-[100%]'>
                    <p className="text-center font-bold">MOBILE APPS</p>
                    {GlobalAnnouncement && GlobalAnnouncement.whatsapp_number &&
                        <div className="animate-bounce flex justify-center mt-5">
                            <a href={`https://wa.me/${GlobalAnnouncement.whatsapp_number}?text=hii`} className='bg-[#32b84a] rounded-[100%]' target="_blank">
                                <img src={Images.WHATSAPP_ICON} className="h-16 w-16 md:h-16 md:w-16" />
                            </a>
                        </div>}
                </div>

                <div className='w-full flex justify-between px-4 py-3 bg-sidebar rounded-lg mt-2'>
                    <TbMailFast size={50} className='text-2xl' />
                    <div className='ml-2 font-bold'>
                        <p>CUSTOMER SERVICE</p>
                        <a href='mailto:support.india@gamebey.com'>support.india@gamebey.com</a>
                    </div>
                </div>

                {/* <div className='w-full flex justify-between px-4 py-3 bg-sidebar rounded-lg mt-2'>
                <TbMailFast size={50} className='text-2xl' />
                <div className='ml-2 font-bold'>
                    <p>MARKETING</p>
                    <p>mailto:support.india@gamebey.com</p>
                </div>
            </div> */}

                <div className='text-center text-xs font-bold mt-1'>
                    <p className=" text-xl text-center font-bold underline">NOTE</p>
                    <p>Listed above are the official accounts owned by GAMEBEY.</p>
                    <p>
                        <span class="span-blocked">Please contact <a href={GlobalAnnouncement && GlobalAnnouncement.whatsapp_number && `https://wa.me/${GlobalAnnouncement.whatsapp_number}?text=hii`} class=" text-red-500" >Live Chat</a> for verification if you are unsure other accounts unlisted here.</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Support;
