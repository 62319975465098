import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { CustomContext } from "../../helper/CustomContext";
import { getStorage } from "../../helper/storage";
import Footer from "./Footer";

export default function Layout({ children, showSidebar }) {
  const [isMode, setIsMode] = useState(false);
  useEffect(() => {
    let mode = localStorage.getItem('theme')
    document.body.classList.add(mode);
    setIsMode(true)
  });


  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const [showKycModal, setShowKycModal] = useState(false);
  const [profileData, setProfileData] = useState(
    getStorage("logged-in")?.result
  );

  return (
    <>
      <CustomContext.ModalContext.Provider
        value={{ showKycModal, setShowKycModal }}
      >
        <CustomContext.ProfileData.Provider
          value={{ profileData, setProfileData }}
        >
          <Header
            toggleTopbar={() => setTopbarOpen(!topbarIsOpen)}
            topbarIsOpen={topbarIsOpen}
          />
          {showSidebar ? (
            <section
              className={(topbarIsOpen ? " collaped" : "") + " _dashBoard_main"}
            >
              <Sidebar topbarIsOpen={topbarIsOpen} />
              <div className="side_mainBox">{children}</div>
            </section>
          ) : (
            children
          )}
          <Footer />
        </CustomContext.ProfileData.Provider>
      </CustomContext.ModalContext.Provider>
    </>
  );
}
