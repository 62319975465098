import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ResponsibleGaming = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">Responsible Gaming</h1>

            <section className="mb-8">
                <p className="mb-4">
                    Gambling problems are associated with poor mental health and can manifest in depression, anxiety, and suicidal thoughts. They also have an adverse effect on family relationships, work, academic performance, and can lead to bankruptcy or crime.
                </p>
                <p className="mb-4">
                    Gambling should be an enjoyable pastime and not a way to make money. Unfortunately, in some cases abuse of gambling can lead to problems. We take care of our customers and make every effort to provide a safe and reliable service which our customers can use without any damaging consequences.
                </p>
                <p className="mb-4">
                    Our company does not allow underage gambling (for persons under the age of 18). We do not advertise our products and services to minors or mentally vulnerable people. We guarantee that our advertising, sponsorship, and marketing activities do not contain any information aimed at attracting underage persons to the Service.
                </p>
                <p className="mb-4">
                    If you share your computer with underage persons, you should make sure that they do not have access to usernames, passwords, and banking details. There is software available for limiting access to gambling, for example NetNanny and Cyber Patrol.
                </p>
                <p className="mb-4">
                    We regularly carry out checks to determine the age of our customers to guarantee that all our players have reached the legal age. In some cases, our checks cannot confirm the customer’s age and we request additional information to confirm that the player has reached the legal age. The availability of a personal account may be limited, and funds are frozen until we receive the necessary information and the fact that you have reached the legal age is confirmed unequivocally.
                </p>
                <p className="mb-4">
                    The legal age for betting and gambling is determined by the laws of your country and is usually 18 years. Our customers should understand that they are the guarantors of their legal registration on 1xbet.com.
                </p>
                <p className="mb-4">
                    Our advertising campaigns and ads do not mislead customers or misrepresent the services that we offer. Customers are informed about their chances of winning and possible risks. Services are provided for payment; excessive spending is not encouraged.
                </p>
                <p className="mb-4">
                    To test the level of your gambling addiction, please answer “Yes” or “No” to the questions below:
                </p>
                <ul className="list-disc pl-5 mb-4">
                    <li className="mb-2">Is your spending out of control?</li>
                    <li className="mb-2">Do you typically borrow money or steal to continue gambling?</li>
                    <li className="mb-2">Have you been spending less time with your family and loved ones lately?</li>
                    <li className="mb-2">Have opinions of others about your activity on gambling Services started to irritate you?</li>
                    <li className="mb-2">Have you lost interest in your hobbies or usual leisure activities?</li>
                    <li className="mb-2">Are you feeling depressed or sometimes even think of suicide because of losing?</li>
                    <li className="mb-2">Have you ever lied to cover up the amount of time or money that you have spent on gambling?</li>
                </ul>
                <p className="mb-4">
                    If you answered “Yes” to most of the questions above, then it is likely that you have a gambling problem. It’s never too late to admit that you have an addiction and to address it. We value our customers and will certainly never contribute to the problem. Please read the following tips to help you reduce the risk of developing a gambling addiction:
                </p>
                <ul className="list-disc pl-5 mb-4">
                    <li className="mb-2">Do not consider gambling as your main source of income.</li>
                    <li className="mb-2">Put a cap on the amount of money and time you want to spend and do not exceed the limits you set.</li>
                    <li className="mb-2">Only gamble with money that you can afford to lose.</li>
                    <li className="mb-2">Avoid the temptation to chase your losses.</li>
                    <li className="mb-2">Do not gamble if you are under the influence of alcohol or drugs, or are feeling depressed.</li>
                </ul>
                <p className="mb-4">
                    For customers who want to set limits on their gambling, we offer a voluntary self-exclusion service, which allows you to close your account or restrict your gambling activities for one of the following periods: 1 month, 6 months, or 1 year. Once your account has been self-excluded, it will be closed until the selected time period has elapsed. Once the self-exclusion period has ended, you will be able to recommence your use of any Services on the website.
                </p>
                <p className="mb-4">
                    You can request that the restrictions be removed from your account before the self-exclusion period has ended; however, the final decision rests with the Company. If you want to set limits on your online activity, then contact us at <a href="mailto:support.india@gamebey.com" className="text-blue-500 hover:underline">support.india@gamebey.com</a>. We may limit the maximum amount of your stake. You can amend these amounts at any time, but any change will be realized only 24 hours after the last update.
                </p>
                <p className="mb-4">
                    During the self-exclusion period, you must not attempt to open a new account, and you must accept that the Company shall have no financial liability and shall not be held otherwise accountable if you continue gambling or using a new account with the Service under a different name or address. In exceptional cases, a customer's account may be unblocked before the self-exclusion period expires.
                </p>
            </section>
        </div>
    );
};

export default ResponsibleGaming;
