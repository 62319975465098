import { SelectField } from "App/components/Common";
import React, { useState } from "react";
import SettingsComponent from "./SettingsComponent";

export const OddSettings = () => {
  const [formValue, setFormValue] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };



  return (
    <div className="innrRight_singleBx">
      <SettingsComponent />
    </div>
  );
};
