import { beforeLoginConstants } from "App/_redux/_constants";
import { getStorage } from "App/utils";

let initialState = {
    before_inFixtureList: [],
    before_upFixtureList: [],
    isLoader: false,
};

export function beforeLogin(state = initialState, action) {
    switch (action.type) {
        case beforeLoginConstants.BEFORE_LOGIN_INPLAY_FIXTURE_REQUEST:
            return {
                ...state,
                before_inFixtureList: { total_records: 0, records: [] },
                isLoader: true,
            };
        case beforeLoginConstants.BEFORE_LOGIN_INPLAY_FIXTURE_SUCCESS:
            // console.log("asdf===>>>", action.data)
            return {
                ...state,
                before_inFixtureList: action.data,
                isLoader: false,
            };
        case beforeLoginConstants.BEFORE_LOGIN_UPCOMING_FIXTURE_REQUEST:
            return {
                ...state,
                before_upFixtureList: { total_records: 0, records: [] },
                isLoader: true,
            };
        case beforeLoginConstants.BEFORE_LOGIN_UPCOMING_FIXTURE_SUCCESS:
            return {
                ...state,
                before_upFixtureList: action.data,
                isLoader: false,
            };

        case beforeLoginConstants.BEFORE_LOGIN_GET_MATCH_DETAIL_REQUEST:
            return {
                ...state,
                isLoader: true,
            };
        case beforeLoginConstants.BEFORE_LOGIN_GET_MATCH_DETAIL_SUCCESS:
            return {
                ...state,
                matchDetail: action.data,
                isLoader: false,
            };



        case beforeLoginConstants.FAILED_RESPONSE:
            return {
                ...state,
                isLoader: false,
            };
        default:
            return state;
    }
}
