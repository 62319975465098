import React from 'react'
import Table from 'react-bootstrap/Table';

export default function CustomTable(props) {
  const { columnData, dataList, isLoading } = props;
  return (
    <div className='tabResponsive'>
      <Table striped bordered hover className={dataList?.length <= 0 ? "noData-table mb-0" : "anvCrd_stmTable"}>
        <thead className="text-center">
          <tr className='anvCrd_stmTR' >
            {columnData?.map((item, ind) => (
              <th align="left" className="anvCrd_stmTH" key={item?.name + ind}>
                {item?.name}
              </th>
            ))}
          </tr>
        </thead>
        <tfoot>
          {isLoading || dataList?.length <= 0 ? (
            <tr>
              <td className="no-data-available" colSpan={9} rowSpan={1}>
                {dataList?.length <= 0 ? "No Records Found!" : <>Loading...</>}
              </td>
            </tr>
          ) : (
            dataList?.map((item, i) => (
              <RowElement key={i} item={item} column={columnData} />
            ))
          )}
        </tfoot>
      </Table>
    </div>
  );
}

const RowElement = ({ item, column }) => (
  <tr>
    {column?.map((colItem, i) => {
      return (
        <>
          <td className='anvCrd_stmTD' align="left" key={i}>
            {item[`${colItem.value}`]}
          </td>
        </>
      );
    })}
  </tr>
);
