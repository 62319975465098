const Images = {
    ICON: require("../../assets/img/logo.png"),
    ICON2: require("../../assets/img/logo2.svg"),
    CASINO: require("../../assets/img/casino.svg"),
    CASINO_PNG: require("../../assets/img/casino.png"),
    SOCCER: require("App/assets/img/soccer_1.svg"),
    TENNIS: require("App/assets/img/tennis-ball_2.svg"),
    CRICKET: require("App/assets/img/cricket-ball_1.svg"),
    FAST_MATKA: require("App/assets/img/fast_matka.svg"),
    MATKA: require("App/assets/img/matka.svg"),
    MAIN_STAR: require("App/assets/img/main_star.svg"),
    KALYAN_STAR: require("App/assets/img/kalyan_star.svg"),
    CLOCK: require("App/assets/img/cloack_icon2.svg"),
    CALENDER: require("App/assets/img/calender.svg"),
    POSITION: require("App/assets/img/position_icon.svg"),
    PIN_MATCH: require("App/assets/img/pinmatch_icon.svg"),
    CUP_ICON: require("App/assets/img/cup-icon.svg"),
    CASINO_ICON: require("App/assets/img/casino_icon.svg"),
    MOON_ICON: require("App/assets/img/moon-icon.svg"),
    VECTOR: require("App/assets/img/Vector_9.svg"),
    POLYGON: require("App/assets/img/polygon.svg"),
    USER_ICON: require("App/assets/img/user_icon.png"),
    POLITICS: require("App/assets/img/politics.svg"),
    SUCCESS_GIF: require("App/assets/img/success.gif"),
    DELETE_PNG: require("App/assets/img/delete.png"),
    BANNER: require("App/assets/img/banner.webp"),
    BANNER_1: require("App/assets/img/banner-1.png"),
    BANNER_2: require("App/assets/img/banner-2.png"),
    BANNER_3: require("App/assets/img/banner-3.png"),
    BANNER_4: require("App/assets/img/banner-4.jpg"),
    BANNER_5: require("App/assets/img/banner-5.jpg"),
    BANNER_6: require("App/assets/img/banner-6.jpg"),

    BANNER_11: require("App/assets/img/image-11.jpg"),
    BANNER_12: require("App/assets/img/image-12.jpg"),
    BANNER_13: require("App/assets/img/image-13.jpg"),
    BANNER_14: require("App/assets/img/image-14.jpg"),
    BANNER_15: require("App/assets/img/image-15.jpg"),
    BANNER_16: require("App/assets/img/image-16.jpg"),
    BANNER_17: require("App/assets/img/image-17.jpg"),
    BANNER_18: require("App/assets/img/image-18.jpg"),


    BANNER_NEW1: require("App/assets/img/Banner_1.webp"),
    BANNER_NEW2: require("App/assets/img/Banner_2.webp"),
    BANNER_NEW3: require("App/assets/img/banner_3.webp"),

    CASINO_BANNER_NEW1: require("App/assets/img/Casino_banner1.webp"),
    CASINO_BANNER_NEW2: require("App/assets/img/Casino_banner2.webp"),
    CASINO_BANNER_NEW3: require("App/assets/img/Casino_banner3.webp"),


    TROPHY: require("App/assets/img/trophy.svg"),
    ALL: require("App/assets/img/all.svg"),

    ALL_PNG: require("App/assets/img/all_sport_1.png"),

    PIN_1: require("App/assets/img/pin_1.svg"),
    PIN: require("App/assets/img/pin.svg"),
    // MIC: require("App/assets/img/mic.svg"),

    INFO: require("App/assets/img/info.svg"),
    TV: require("App/assets/img/tv.svg"),
    CHART: require("App/assets/img/chart.svg"),

    HOME: require("App/assets/img/home.svg"),
    PLAY: require("App/assets/img/play.svg"),
    STAR: require("App/assets/img/star.svg"),
    BILLIARD: require("App/assets/img/billiard-ball.svg"),
    SNOOKER: require("App/assets/img/snooker.svg"),
    HORSE: require("App/assets/img/horse.svg"),
    PLAY_NOW: require("App/assets/img/play_nowImg.png"),
    PLAY_PNG: require("App/assets/img/play.png"),
    GIFT: require("App/assets/img/gift.png"),


    USERIMAGE: require("App/assets/img/userimg.png"),
    LOCK: require("App/assets/img/lock.png"),


    CASINO1: require("App/assets/img/ae_sexy_logo.png"),
    CASINO2: require("App/assets/img/ezugi_logo.png"),
    CASINO3: require("App/assets/img/qtech-logo.png"),
    CASINO4: require("App/assets/img/supernowa.png"),
    CASINO5: require("App/assets/img/xpro.png"),


    GROUPCOPY1: require("App/assets/img/groupImg1.png"),
    GROUPCOPY2: require("App/assets/img/groupImg2.png"),

    HAMICON: require("App/assets/img/hamberg_icon.png"),
    BACKICON: require("App/assets/img/next_back.png"),

    BN_HOME: require("App/assets/img/bn-home.svg"),
    BN_INPLAY: require("App/assets/img/bn-inplay.svg"),
    BN_CASINO: require("App/assets/img/bn-casino.svg"),
    BN_ACCOUNT: require("App/assets/img/bn-account.svg"),
    BN_PROFILE: require("App/assets/img/bn-profile.svg"),

    ABN_HOME: require("App/assets/img/abn-home.svg"),
    ABN_INPLAY: require("App/assets/img/abn-inplay.svg"),
    ABN_ACCOUNT: require("App/assets/img/abn-account.svg"),
    ABN_PROFILE: require("App/assets/img/abn-profile.svg"),


    PROFILE_USERICON: require("App/assets/img/largeUser_icon.png"),


    INSTANTTRANSFER: require("App/assets/img/instant-transfer.png"),
    BANKTRANSFER: require("App/assets/img/banktransfer.png"),
    PAYTM: require("App/assets/img/paytm_icon.png"),
    AMAZONPAY: require("App/assets/img/amazon-pay.png"),
    GPAY: require("App/assets/img/googlepay.png"),
    UPI: require("App/assets/img/upi.png"),
    QRCODE: require("App/assets/img/QR_Code.png"),
    PHONEPE: require("App/assets/img/Phonepe_icon.webp"),
    CRYPTO: require("App/assets/img/Crypto_new.jpg"),

    WHATSAPP_ICON: require("App/assets/img/whatsapp.png"),
    DOWNLOAD_APP_BANNER_WEB: require("App/assets/img/download_app_banner_web.webp"),
    DOWNLOAD_APP_BANNER_MOBILE: require("App/assets/img/download_app_banner_mobile.webp"),

    SOCIAL: require("App/assets/img/social.png"),

    DISCORD: require("App/assets/img/discord.svg"),
    FACEBOOK: require("App/assets/img/facebook.svg"),
    INSTAGRAM: require("App/assets/img/instagram.svg"),
    TELEGRAM: require("App/assets/img/telegram.svg"),
    WHATSAPP: require("App/assets/img/whatsapp.svg"),

    EGUZI_LOGO: require("App/assets/img/ezugiLogoNew.webp"),
    AG_LOGO: require("App/assets/img/aGLogoNew.webp"),
    BET_GAMES_LOGO: require("App/assets/img/betGamesLogoNew.webp"),
    EVO_LOGO: require("App/assets/img/evolutionGamesNew.webp"),
    NETENT_LOGO: require("App/assets/img/netentLogoNew.webp"),
    TVBET_LOGO: require("App/assets/img/tvbetLogoNew.webp"),
    SUPER_SPADE_LOGO: require("App/assets/img/superSpadeLogoNew.webp"),
    SUPERNOVA_LOGO: require("App/assets/img/supernowaLogoNew.webp"),
    BTI_LOGO: require("App/assets/img/BTILogoNew.webp"),
    MOJ_LOGO: require("App/assets/img/7mojosIcon.webp"),
    SPRIBE_LOGO: require("App/assets/img/spribe_logo_footer.webp"),

    NET_BANK_LOGO: require("App/assets/img/internetBanking.webp"),
    GPAY_LOGO: require("App/assets/img/gpayLogoNew.webp"),
    UPI_LOGO: require("App/assets/img/upiLogoNew.webp"),
    PAYTM_LOGO: require("App/assets/img/Paytm.png"),
    PHONEPE_LOGO: require("App/assets/img/Phonepay.png"),

    CRYPTO_LOGO: require("App/assets/img/Crypto-imgpng.png"),
    CRYPTO_SCANNER: require("App/assets/img/crypto-scanner.jpeg"),

    FANDUEL: require("App/assets/img/fanduel.png"),




}

export default Images