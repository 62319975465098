import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import AppConfig from "../../lib/app-config";
import { getDateAndTime, asteriskList } from "App/utils";

const MatkaMatchList = (props) => {
  const { eventList } = props;
  const [isFetch, setForceFetch] = useState(false);

  useEffect(() => {
    let socket = io(AppConfig.socketurl, {
      reconnectionDelayMax: 100000,
      extraHeaders: {},
    });
    createConnection(socket);
    return () => {
      socket.off("matka_result");
      socket.close();
    };
  }, []);

  useEffect(() => {
    const payload = {
      sport_id: 999,
      event_id: [],
      current_page: 1,
      items_perpage: 100,
    };
    if (isFetch) {
      setForceFetch((s) => !s);
    }
  }, [isFetch]);

  const createConnection = useCallback((socket) => {
    socket.on("connect", () => {
      console.log("socket connected", socket.connected); // true
    });
    socket.on("matka_result", handleMatkaResult);
  }, []);

  const handleMatkaResult = (res) => {
    if (res?.event_id) {
      setForceFetch((s) => !s);
    }
  };

  const getMatkaValue = (result, ind) => {
    const res = result ? result?.split("-")?.join("") : [];
    return res[ind] ? res[ind] : "*";
  };

  return (
    <>
      <div className="max-w-[1409px] mx-auto !px-10">
        <div className="flex flex-col gap-4 max-w-[1053px] mx-auto">
          {eventList.map((match, index) => (
            <div key={index + match.open_date}>
              <div className="flex flex-col-reverse sm:flex-row">
                {/* <div className="col-xs-12 top-time-dtl in-play-right-box-color">
                  {getDateAndTime("DD MMMM hh:mm A", match.open_date)}
                </div> */}
                <div
                  className="matkaDetail "
                  style={{
                    backgroundColor: match?.background_color
                      ? match?.background_color
                      : "#ffffff",
                    color: "#262626",
                    zIndex: 1,
                  }}
                >
                  <Link
                    to={"/matka/" + match.event_id}
                    className="chart_link_box team-name-font-color text-xl sm:text-2xl"
                    style={{ marginTop: 0 }}
                  >
                    <b
                      style={{
                        color: match?.title_color ? match?.title_color : "#D9AC33",
                      }}
                    >
                      {match.event_name.toUpperCase()}
                    </b>
                  </Link>
                  <div className="text-[20px]">
                    {asteriskList.map((item, index) => (
                      <React.Fragment key={index}>
                        {index === 5 && <span> - </span>}
                        {index === 3 || index === 4 ? (
                          <sup>
                            {getMatkaValue(match?.matka_game_result, index)}
                          </sup>
                        ) : (
                          getMatkaValue(match?.matka_game_result, index)
                        )}
                        {index === 2 && <span> - </span>}
                      </React.Fragment>
                    ))}
                  </div>
                  <Link
                    to={
                      "/matka-chart/" +
                      match?.event_name.replace(/Open|Close/g, "") +
                      "/" +
                      match.category_id
                    }
                    className="text-[13px]"
                  >
                    <b
                      style={{
                        color: match?.title_color ? match?.title_color : "#D9AC33",
                      }}
                    >
                      {match?.event_name.replace(/Open|Close/g, "")} चार्ट
                    </b>
                  </Link>
                </div>

                <div className="w-full sm:w-[175px]">
                  <div className="matka_dateDetail">

                    <p className="text-xs sm:text-sm text-[#262626] dark:text-white font-medium">
                      {getDateAndTime("DD", match.open_date)}
                    </p>
                    <p className="text-xs sm:text-sm text-[#262626] dark:text-white font-medium">
                      {getDateAndTime("MMMM", match.open_date)}
                    </p>
                    <p className="text-xs sm:text-sm text-[#262626] dark:text-white font-medium">
                      {getDateAndTime("hh:mm A", match.open_date)}
                    </p>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default MatkaMatchList;
