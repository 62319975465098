import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { BsFillLockFill } from "react-icons/bs";
import { getDateAndTime, getLeftBazarTime } from "App/utils";
import { cancelMatkaBet } from "App/_redux/_services";
import ConfirmModal from "App/components/Common/ConstomModal/ConfirmModal";

export const MatkaBetList = (props) => {
  const { marketBetData, betClosed, event, fetchMatkaBetList } = props;
  const [togglelist, setToggleList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cancelModal, setCancelBetModal] = useState(false);

  const getDisplayValue = (value, market_type) => {
    try {
      if (value) {
        if (market_type) {
          if (market_type === 1 || market_type === 4 || market_type === 5) {
            let val = value * 100;
            return (parseFloat(val).toFixed(2) - 1 * 100) / 100;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val).toFixed(2) - 1 * 100;
          } else {
            return parseFloat(value).toFixed(2);
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const cancelBet = async (betslip_uid) => {
    if (
      getLeftBazarTime(
        getDateAndTime("YYYY-MM-DD HH:mm:ss", event?.open_date)
      ) !== "00:00:00"
    ) {
      setLoader(true);
      try {
        const res = await cancelMatkaBet({ betslip_uid });
        if (res) {
          toast.success(res?.message ? res?.message : "success");
          fetchMatkaBetList((s) => !s);
        }
        setTimeout(() => {
          window.location.reload();
          setLoader(false);
        }, 500);
      } catch (error) {
        toast.error(
          error.response?.data?.error
            ? error.response?.data?.error
            : "server error"
        );
        setLoader(false);
      }
    } else {
      toast.error("Bazar is closed");
    }
  };

  const toggleDetailsMenu = (name) => {
    const detailsArray = [...togglelist];
    if (detailsArray.indexOf(name) >= 0) {
      detailsArray.splice(detailsArray.indexOf(name), 1);
    } else {
      detailsArray.push(name);
    }
    setToggleList(detailsArray);
  };

  const getBetClose = () => {
    if (typeof betClosed === "boolean") {
      switch (true) {
        case betClosed == true:
          return true;
        default:
          return false;
      }
    } else {
      switch (true) {
        case betClosed == 1:
          return false;
        case betClosed == 0:
          return true;
        default:
          return false;
      }
    }
  };

  return (
    <>
      <div className="pt-3 pb-3 basis-full md:basis-full lg:basis-[505px]">
        <div className="flex flex-col gap-3">
          {marketBetData?.length > 0 ? (
            marketBetData.map((item) => (
              <div>
                <div className="akdaHead p-[10px_15px_10px_20px] flex justify-between items-center rounded-[8px_8px_0_0]">
                  <span className="text-sm font-semibold">
                    {item?.market_name}
                    {" { " + item?.list?.length + " }"}
                  </span>
                  <span>
                    <div
                      onClick={() => toggleDetailsMenu(item?.market_name)}
                      className="bet_list_arrow"
                    >
                      {togglelist ? (
                        <AiOutlineDown style={{ marginLeft: "10px" }} />
                      ) : (
                        <AiOutlineUp style={{ marginLeft: "10px" }} />
                      )}
                    </div>
                  </span>
                </div>
                <table className="akd_table">
                  <thead>
                    {!togglelist.includes(item?.market_name) && (
                      <tr className="akd_table">
                        <th>Patti</th>
                        <th>No.</th>
                        <th>Amt</th>
                        <th>Date</th>
                        <th>Pur. Time</th>
                        <th>Draw Time</th>
                        <th>Action</th>
                      </tr>
                    )}
                  </thead>
                  {!togglelist.includes(item?.market_name) && (
                    <tbody>
                      {item?.list?.length ? (
                        item?.list?.map((market, index) => (
                          <tr key={index} id="runs-bet-value-box-color">
                            <td style={{ textAlign: "left" }}>
                              {market.market_name}
                            </td>
                            <td>{market.team_name}</td>
                            <td>{getDisplayValue(market.stake)}</td>
                            <td>
                              {getDateAndTime(
                                "DD, MMM YYYY",
                                market.created_at
                              )}
                            </td>
                            <td>
                              {getDateAndTime(
                                "DD, MMM YYYY hh:mm",
                                market.created_at
                              )}
                            </td>
                            <td>
                              {getDateAndTime("h:mm A", market.game_start_date)}
                            </td>
                            <td>
                              <button
                                disabled={
                                  getBetClose() ||
                                  loader === market?.betslip_uid ||
                                  +market?.winning_status !== 0
                                }
                                amt-value="clear"
                                className={
                                  +market?.winning_status === 0
                                    ? "bet_btn submit cancle_nw"
                                    : "cancle_akdaBtn"
                                }
                                onClick={() => {
                                  setCancelBetModal(market?.betslip_uid);
                                }}
                              >
                                {getBetClose() && (
                                  <div className="lock_bet_cancle">
                                    <BsFillLockFill size={26} color="red" />
                                  </div>
                                )}
                                {+market?.winning_status === 0
                                  ? "Cancel"
                                  : "Cancelled"}
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr key={"no"} id="runs-bet-value-box-color">
                          <td colSpan={7}>
                            {" "}
                            <div
                              className="d-flex "
                              style={{
                                justifyContent: "center",
                                marginTop: "10px",
                              }}
                            >
                              <p>No Record Found</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            ))
          ) : (
            <div className="akdaHead p-[10px_15px_10px_20px] flex justify-between items-center rounded-[8px_8px_0_0]">
              <span className="text-sm font-semibold">
                {event?.market_name}
                {" { " + 0 + " }"}
              </span>
              <span>
                <div
                  className="bet_list_arrow"
                >
                  {togglelist ? (
                    <AiOutlineDown style={{ marginLeft: "10px" }} />
                  ) : (
                    <AiOutlineUp style={{ marginLeft: "10px" }} />
                  )}
                </div>
              </span>
            </div>
          )}
          {cancelModal && (
            <ConfirmModal
              isOpen={Boolean(cancelModal)}
              message={"Are you sure, you want to cancel Bet"}
              onClose={() => setCancelBetModal(false)}
              onSubmit={() => cancelBet(cancelModal)}
              btnTitle={"yes"}
              isLoading={Boolean(loader)}
              isDisabled={Boolean(loader)}
            />
          )}
        </div>
      </div>
    </>
  );
};
