
import React, { Fragment, useEffect, useState } from 'react'
import { loginUser, registerUserRequest, toggleAuthModalRequest } from "App/_redux/_actions";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Images } from "App/constants";
import { otpVerificationRequest, signupRequest } from 'App/services';
import OtpTimer from 'otp-timer'

const RegisterModal = ({ showModal, toggle }) => {
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    const [error, setError] = useState(null)
    const [isOtpSended, setOtpSended] = useState(false)
    const [isReferralCode, setReferralCode] = useState(false)
    const [state, setState] = useState({
        username: "",
        password: "",
        re_password: "",
        referral_code: "",
        mobile: "",
        otp: "",
        orderId: null
    })


    useEffect(() => {
        let is_ref = searchParams.get('ref')
        if (is_ref) {
            setReferralCode(true)
            setState(prevState => ({
                ...prevState,
                referral_code: is_ref
            }))
        }
    }, [])



    const navigate = useNavigate();

    const submit = async () => {

        try {
            dispatch(
                // signupRequest(state, (result) => {
                registerUserRequest(state, (result) => {
                    if (result.error) {
                        setError(result.error)
                        // toast.error(result.error);
                        return
                    }
                    if (result.data.token) {
                        toggleAuthModal(null);
                        navigate('/all-sports');
                    } else {
                        toggleAuthModal(null);
                        navigate('/login');
                        toast.success("Register successfull");
                    }
                }))
        } catch (error) {
            // console.log("error", error)
        }
    };


    const handleChange = (e) => {
        const { id, value } = e.target

        if (id == "username" && /[^0-9a-zA-Z]/.test(value)) {
            toast.error(`Character not allowed`)
            return
        }
        setState(prevState => ({
            ...prevState,
            [id]: id == "username" ? value.replace(" ", "") : value
        }))
        setError(S => ({
            ...S,
            [id]: null
        }))
    }


    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const requestOtp = async () => {
        setOtpSended(true)
        const param = {
            mobile: state.mobile,
            type: 1
        }
        try {
            dispatch(otpVerificationRequest(param, (response) => {
                if (response.error) {
                    setError(response.error)
                    setOtpSended(false)
                    toast.error(response.error);
                    return
                }

                if (response && response.data && response.data.orderId) {
                    setState(prevState => ({
                        ...prevState,
                        orderId: response.data.orderId
                    }))
                }

                if (response.data.message != "") {
                    toast.success(response.message);
                    // setTimeout(() => setOtpSended(false), 30000);
                    // clearTimeout()

                }

            }))
        } catch (error) { }
    }


    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    return (
        <Fragment>
            {true ? (
                <div className="loginModal_otr" >
                    <div class="relative w-full max-w-[503px] z-10">
                        <div class="relative bg-secondary text-secondary-foreground rounded-lg shadow ">
                            <div class="loginModal_header">
                                <div className='basis-full flex justify-center'>
                                    <h4 className=' text-[24px] font-bold py-2'>Register</h4>
                                    <button onClick={() => toggleAuthModal(null)} type="button" class="anvModal_close regModal-close" data-modal-hide="authentication-modal">
                                        <svg class="w-3 h-3" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLnejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                            </div>
                            <div className="px-[28px] pt-4 pb-[30px]">
                                <div className="space-y-[14px]" >
                                    <div className='inputsBx_outer'>
                                        <div className=' absolute left-3 top-[12px]'><img src={Images.USERIMAGE} alt="" /></div>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            class="custom_inputTw regCustom_inputTw"
                                            placeholder="username"
                                            required
                                            value={state.username}
                                            onChange={handleChange}
                                        />
                                        {error && error.username && error.username != "" && <small className="text-red-500">{error.username}</small>}
                                    </div>
                                    <div className='inputsBx_outer'>
                                        <div className=' absolute left-3 top-[12px]'><img src={Images.LOCK} alt="" /></div>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="Password"
                                            className="custom_inputTw regCustom_inputTw"
                                            required
                                            value={state.password}
                                            onChange={handleChange}
                                        />
                                        {error && error.password && error.password != "" && <small className="text-red-500">{error.password}</small>}
                                    </div>

                                    <div className='inputsBx_outer'>
                                        <div className=' absolute left-3 top-[12px]'><img src={Images.LOCK} alt="" /></div>
                                        <input
                                            type="password"
                                            name="re_password"
                                            id="re_password"
                                            placeholder="Re-enter password"
                                            className="custom_inputTw regCustom_inputTw"
                                            required
                                            value={state.re_password}
                                            onChange={handleChange}
                                        />
                                        {error && error.re_password && error.re_password != "" && <small className="text-red-500">{error.re_password}</small>}
                                    </div>

                                    <div className='inputsBx_outer'>
                                        <input
                                            type="number"
                                            name="mobile"
                                            id="mobile"
                                            maxLength={10}
                                            onInput={maxLengthCheck}
                                            class="custom_inputTw simCustom_inputTw"
                                            placeholder="Mobile Number"
                                            required
                                            value={state.mobile}
                                            onChange={handleChange}
                                        />
                                        {error && error.mobile && error.mobile != "" && <small className="text-red-500">{error.mobile}</small>}
                                        <div className=' absolute right-[6px] top-[5px]'>
                                            {/* <button className={'get_otp ' + (state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]")}
                                                disabled={state.mobile.length != 10 || isOtpSended} onClick={() => requestOtp()}>
                                        
                                                {isOtpSended ? <OtpTimer
                                                    minutes={0}
                                                    seconds={30}
                                                    textColor={"#fff"}
                                                    text="Resend in :"
                                                    ButtonText="Resend"
                                                    resend={() => requestOtp()}
                                                /> : "Get OTP"}
                                            </button> */}

                                            {isOtpSended ?
                                                <button className={'get_otp text-nowrap ' + (state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]")}>
                                                    <OtpTimer
                                                        // minutes={0}
                                                        seconds={30}
                                                        textColor={"#fff"}
                                                        text="Resend in :"
                                                        ButtonText="Resend"
                                                        resend={() => requestOtp()}
                                                        background={state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]"}
                                                    />
                                                </button> :
                                                <button className={'get_otp text-nowrap ' + (state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]")}
                                                    disabled={state.mobile.length != 10 || isOtpSended}
                                                    onClick={() => requestOtp()}>
                                                    Get OTP
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    {isOtpSended && <div className='inputsBx_outer'>
                                        <input
                                            type="number"
                                            name="otp"
                                            id="otp"
                                            class="custom_inputTw simCustom_inputTw"
                                            placeholder="OTP"
                                            required
                                            value={state.otp}
                                            onChange={handleChange}
                                        />
                                        {error && error.otp && error.otp != "" && <small className="text-red-500">{error.otp}</small>}
                                    </div>}

                                    <div className='inputsBx_outer'>
                                        {<label for="username" class="block mb-2 text-sm font-medium  cursor-pointer" onClick={() => setReferralCode(!isReferralCode)}>Have Referral Code ?</label>}
                                        {isReferralCode && <Fragment>
                                            <input
                                                type="text"
                                                name="referral_code"
                                                id="referral_code"
                                                class="custom_inputTw simCustom_inputTw"
                                                placeholder="Referral Code"
                                                required
                                                value={state.referral_code}
                                                onChange={handleChange}
                                            />
                                            {error && error.referral_code && error.referral_code != "" && <small className="text-red-500">{error.referral_code}</small>}
                                        </Fragment>}
                                    </div>

                                    <div className='inputsBx_outer flex items-start gap-4 '>
                                        <input type="checkbox" name="" id="terms" checked disabled className=' scale-[1.8] relative left-2 top-2 rounded-md' />
                                        <label htmlFor="terms" className='text-sm '><span className=''>I confirm that I am over 18 years old</span> , and accept the Terms and Conditions and Privacy Policy.</label>
                                    </div>

                                </div>
                                <button type="submit"
                                    onClick={() => submit()}
                                    disabled={state.username == "" || state.password == "" || state.re_password == "" || state.mobile == "" || state.otp == ""}
                                    className="anvReg_btn ">
                                    <span className='relative z-10'>Sign Up</span>
                                </button>

                                <div className='mt-[20px] text-center'>
                                    <span className='text-sm leading-4 font-medium'>Already Registered | <button onClick={() => toggleAuthModal("LOGIN")}> Log In</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='backDrop backdrop-blur-md'></div>
                </div>
            ) : null}
        </Fragment>
    )
}

export default RegisterModal