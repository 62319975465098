import axios from "axios";
import axiosAuth from "App/lib/axios";
import { toast } from "react-hot-toast";
import config from "App/lib/app-config";
import { getStorage } from "App/utils";

export const getLedger = () => {
  return axiosAuth
    .post(
      config.api_baseurl + "/ledger_data",
      {},
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

/////////// ledger details api ////////////


export const ledgerDetails = (data) => {
  return axiosAuth
    .post(
      config.api_baseurl + "ledger_detail",
      data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const userAccountStatement = (data) => {
  return axiosAuth
    .post(config.api_baseurl + "/account_statement", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
