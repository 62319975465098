import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { IoMdArrowBack } from "react-icons/io";
import { getMatkaCategoryResult } from "App/_redux/_services";
import "../MatkaMatchList/matkaChart.css";

const BeforeLoginMatkaChartDetail = () => {
    const { event_id, cate_id } = useParams();
    const navigate = useNavigate();
    const todayDate = moment(new Date()).startOf("isoWeek");
    const [selectedDateRange, setSelectedDateRange] = useState([]);

    const [filterData, setFilter] = useState({
        startDate: todayDate.clone().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: todayDate.clone().add(6, "days").format("YYYY-MM-DD"),
        current_page: 1,
        items_perpage: 20,
    });

    useEffect(() => {
        getCategoryDetails();
    }, [cate_id]);

    const getCategoryDetails = async () => {
        const { startDate, endDate } = filterData;
        const payload = {
            category_id: cate_id,
        };
        if (startDate && endDate) {
            payload["from_date"] = moment(startDate).format("YYYY-MM-DD");
            payload["to_date"] = moment(endDate).format("YYYY-MM-DD");
        }
        try {
            const res = await getMatkaCategoryResult(payload);
            const records = res?.data?.records ? res?.data?.records : [];
            const dateArray = [];
            const currentDate = moment(startDate).startOf("isoWeek");
            const end = moment(endDate).endOf("isoWeek");

            while (currentDate.isSameOrBefore(end)) {
                const monday = currentDate.clone().format("YYYY-MM-DD");
                const sunday = currentDate.clone().add(6, "days").format("YYYY-MM-DD");

                const recordsWithinWeek = records
                    .filter((record) =>
                        moment(record.open_date, "YYYY-MM-DD").isBetween(
                            monday,
                            sunday,
                            null,
                            "[]"
                        )
                    )
                    .map((record) => {
                        const dayName = moment(record.open_date).format("d");
                        return { ...record, dayName };
                    });

                dateArray.push({
                    weekRange: {
                        start: currentDate.clone().format("DD-MM-YYYY"),
                        end: currentDate.clone().add(6, "days").format("DD-MM-YYYY"),
                    },
                    records: recordsWithinWeek,
                });

                currentDate.add(7, "days");
            }

            setSelectedDateRange(dateArray);
        } catch (error) { }
    };

    const handleSearch = () => {
        if (filterData?.startDate && filterData.endDate) {
            getCategoryDetails();
        } else {
        }
    };

    const handleSelectDate = (name, value) => {
        setFilter((s) => ({ ...s, [name]: value }));
    };

    const asteriskBox = (records, day) => {
        const matkaResult = records.find(
            (item) => item?.dayName === day
        )?.matka_game_result;
        const numArray = matkaResult
            ? matkaResult?.replace(/-/g, "")?.split("")
            : "";

        return (
            <div className="chrtChar_bx">
                <div className="chrtChar_row">
                    <div className="chrtChar_innrFx">
                        {numArray[0] ? numArray[0] : "*"}
                    </div>
                    <div className="chrtChar_innrFx"></div>
                    <div className="chrtChar_innrFx">
                        {numArray[5] ? numArray[5] : "*"}
                    </div>
                </div>

                <div className="chrtChar_row">
                    <div className="chrtChar_innrFx">
                        {numArray[1] ? numArray[1] : "*"}
                    </div>
                    <div className="chrtChar_innrFx middle-cht">
                        {numArray[3] ? numArray[3] : "*"} {numArray[4] ? numArray[4] : "*"}
                    </div>
                    <div className="chrtChar_innrFx">
                        {numArray[6] ? numArray[6] : "*"}
                    </div>
                </div>

                <div className="chrtChar_row">
                    <div className="chrtChar_innrFx">
                        {numArray[2] ? numArray[2] : "*"}
                    </div>
                    <div className="chrtChar_innrFx"></div>
                    <div className="chrtChar_innrFx">
                        {numArray[7] ? numArray[7] : "*"}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-[1409px] mx-auto px-[6px] sm:px-2">
            <div className="bg-[#EDEDED] p-4 mt-2 mb-6 rounded-[1px]">
                <div className="statementFlx ">
                    <div className="basis-full flex justify-between items-center">
                        <IoMdArrowBack
                            color="#000"
                            size={"24px"}
                            className="pointer"
                            onClick={() => navigate(-1)}
                        />
                        <h4 className="h4_semiTxt">{event_id.toUpperCase()}</h4>
                    </div>

                    <div className="inputsOtr_bx basis-full">
                        <div className="max-w-[1216px] ml-auto">
                            <div className="searchFlexBx">
                                <div className="inputsFrt_fx">
                                    <div className="inpBasis">
                                        <label className="lableTxt" htmlFor="">
                                            Start Date
                                        </label>
                                        <input
                                            type="date"
                                            className="inpBx"
                                            value={filterData?.startDate}
                                            max={moment(new Date()).format("YYYY-MM-DD")}
                                            onChange={(e) =>
                                                handleSelectDate("startDate", e.target.value)
                                            }
                                        />
                                    </div>

                                    <div className="inpBasis">
                                        <label className="lableTxt" htmlFor="">
                                            End Date
                                        </label>
                                        <input
                                            type="date"
                                            className="inpBx"
                                            value={filterData?.endDate}
                                            min={filterData?.startDate}
                                            max={moment(new Date()).format("YYYY-MM-DD")}
                                            onChange={(e) =>
                                                handleSelectDate("endDate", e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="inputsSecd_fx">
                                    <button
                                        className="srchBtn"
                                        onClick={() => handleSearch(true)}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-5 mob_view anv-margTop-2">
                <div className="chart_table_box">
                    <table className="mainMatka_table">
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ width: "70px" }}>DATE</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>MONDAY</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>TUESDAY</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>WEDNESDAY</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>THURSDAY</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>FRIDAY</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>SATURDAY</div>
                                </th>
                                <th>
                                    <div style={{ width: "70px" }}>SUNDAY</div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {selectedDateRange?.map((item, ind) => (
                                <tr key={ind}>
                                    <td>
                                        <div style={{ width: "120px", color: "#4b4186" }}>
                                            {item?.weekRange?.start} <br />
                                            <br />
                                            TO
                                            <br />
                                            <br />
                                            {item?.weekRange?.end}
                                        </div>
                                    </td>

                                    <td>{asteriskBox(item?.records, "1")}</td>

                                    <td>{asteriskBox(item?.records, "2")}</td>

                                    <td>{asteriskBox(item?.records, "3")}</td>

                                    <td>{asteriskBox(item?.records, "4")}</td>

                                    <td>{asteriskBox(item?.records, "5")}</td>

                                    <td>{asteriskBox(item?.records, "6")}</td>

                                    <td>{asteriskBox(item?.records, "0")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default BeforeLoginMatkaChartDetail