import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const AffiliateProgram = () => {

    const { pathname } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">Affiliate Program</h1>

            <section className="mb-8">
                <p className="mb-4">
                    By becoming a partner of the company, you can rely on the following advantages:
                </p>
                <ul className="list-disc pl-5 mb-4">
                    <li className="mb-2">Partners can have a wider coverage by attracting new users for both betting and gambling services;</li>
                    <li className="mb-2">Participants will have access to a lot of marketing materials that will help them promote the company;</li>
                    <li className="mb-2">You can choose between RevShare, CPA, and hybrid payment schemes;</li>
                    <li className="mb-2">The company can negotiate individual conditions with some partners.</li>
                </ul>
                <p className="mb-4">
                    Each new affiliate participant receives a (%) RevShare commission for attracting new players to the sportsbook. By continuing to work with the company, rules may be changed individually.
                </p>
                <p className="mb-4">
                    After joining the Gamebey affiliate program, you have access to a lot of types of marketing materials, including:
                </p>
                <ul className="list-disc pl-5 mb-4">
                    <li className="mb-2">Banners;</li>
                    <li className="mb-2">Landing pages;</li>
                    <li className="mb-2">Applications.</li>
                </ul>
                <p className="mb-4">
                    This will allow you to focus on creating new sources of targeted traffic instead of thinking about the marketing part.
                </p>

                <h2 className="text-xl font-semibold mb-4">Types of Cooperation</h2>
                <p className="mb-4">
                    The affiliate program includes three types of cooperation. You can choose between:
                </p>
                <ul className="list-disc pl-5 mb-4">
                    <li className="mb-2">Pure RevShare with a (%) commission in INR for each invited user.</li>
                    <li className="mb-2">A fixed CPA payment for each referral. If you choose this option, you will receive a one-time payment for each user who registered and deposited in Gamebey.</li>
                    <li className="mb-2">A hybrid scheme, which allows you to receive both CPA (a smaller amount compared to pure CPA) and a RevShare.</li>
                </ul>

                <h2 className="text-xl font-semibold mb-4">How to Become a Gamebey Partner</h2>
                <p className="mb-4">
                    To become a partner of Gamebey, one has to:
                </p>

                <h3 className="text-lg font-semibold mb-2">STEP 1</h3>
                <p className="mb-4">
                    Visit the official affiliate website from a desktop device or run the Gamebey app. You can find it by clicking on the dropdown menu in the top-left corner, scrolling down, and pressing on the <strong>Become an Affiliate</strong> link.
                </p>
                <Link to={currentUser && currentUser.username ? "/affiliate-request" : "/affiliate-request-d"} className="text-blue-500 hover:underline mb-4 block">Open Gamebey Affiliate Page</Link>

                <h3 className="text-lg font-semibold mb-2">STEP 2</h3>
                <p className="mb-4">
                    Click <strong>Registration</strong> and complete a form by providing your email, phone number, and username in one of the social media, traffic sources, and traffic geo.
                </p>
                <Link to={currentUser && currentUser.username ? "/affiliate-request" : "/affiliate-request-d"} className="text-blue-500 hover:underline mb-4 block">Click Registration Button to register at Gamebey Affiliate</Link>

                <h3 className="text-lg font-semibold mb-2">STEP 3</h3>
                <p>
                    Complete registration by accepting the Privacy Policy and Terms and Conditions, as well as clicking <strong>Sign Up</strong>.
                </p>
                <Link to={currentUser && currentUser.username ? "/affiliate-request" : "/affiliate-request-d"} className="text-blue-500 hover:underline">Complete Registration at Gamebey Affiliate Program</Link>
            </section>
        </div>
    );
};

export default AffiliateProgram;
