export const matchConstants = {

  GET_CASINO_BANNERS_REQUEST: "GET_CASINO_BANNERS_REQUEST",
  GET_CASINO_BANNERS_SUCCESS: "GET_CASINO_BANNERS_SUCCESS",

  GET_BANNERS_REQUEST: "GET_BANNERS_REQUEST",
  GET_BANNERS_SUCCESS: "GET_BANNERS_SUCCESS",

  GET_CLIENT_ANNOUNCEMENT_REQUEST: "GET_CLIENT_ANNOUNCEMENT_REQUEST",
  GET_CLIENT_ANNOUNCEMENT_SUCCESS: "GET_CLIENT_ANNOUNCEMENT_SUCCESS",

  GET_MASTER_DATA_REQUEST: "GET_MASTER_DATA_REQUEST",
  GET_MASTER_DATA_SUCCESS: "GET_MASTER_DATA_SUCCESS",

  GET_GAMES_LIST_REQUEST: "GET_GAMES_LIST_REQUEST",
  GET_GAMES_LIST_SUCCESS: "GET_GAMES_LIST_SUCCESS",

  GET_PAYMENT_INFO_REQUEST: "GET_PAYMENT_INFO_REQUEST",
  GET_PAYMENT_INFO_SUCCESS: "GET_PAYMENT_INFO_SUCCESS",

  GET_DEPOSIT_PAYMENT_REQUEST: "GET_DEPOSIT_PAYMENT_REQUEST",
  GET_DEPOSIT_PAYMENT_SUCCESS: "GET_DEPOSIT_PAYMENT_SUCCESS",

  GET_SPORTS_LEAGUES_REQUEST: "GET_SPORTS_LEAGUES_REQUEST",
  GET_SPORTS_LEAGUES_SUCCESS: "GET_SPORTS_LEAGUES_SUCCESS",

  GET_LEAGUE_EVENTS_REQUEST: "GET_LEAGUE_EVENTS_REQUEST",
  GET_LEAGUE_EVENTS_SUCCESS: "GET_LEAGUE_EVENTS_SUCCESS",

  GET_USER_BALANCE_REQUEST: "GET_USER_BALANCE_REQUEST",
  GET_USER_BALANCE_SUCCESS: "GET_USER_BALANCE_SUCCESS",

  GET_ALL_SPORT_REQUEST: "GET_ALL_SPORT_REQUEST",
  GET_ALL_SPORT_SUCCESS: "GET_ALL_SPORT_SUCCESS",

  GET_SELECTED_SPORT: "GET_SELECTED_SPORT",
  GET_SELECTED_SPORT_TAB: "GET_SELECTED_SPORT_TAB",

  GET_UPCOMING_FIXTURE_REQUEST: "GET_UPCOMING_FIXTURE_REQUEST",
  GET_UPCOMING_FIXTURE_SUCCESS: "GET_UPCOMING_FIXTURE_SUCCESS",

  GET_INPLAAY_FIXTURE_REQUEST: "GET_INPLAAY_FIXTURE_REQUEST",
  GET_INPLAAY_FIXTURE_SUCCESS: "GET_INPLAAY_FIXTURE_SUCCESS",

  GET_PINNED_MATCHES_REQUEST: "GET_PINNED_MATCHES_REQUEST",
  GET_PINNED_MATCHES_SUCCESS: "GET_PINNED_MATCHES_SUCCESS",

  GET_MATCH_DETAIL_REQUEST: "GET_MATCH_DETAIL_REQUEST",
  GET_MATCH_DETAIL_SUCCESS: "GET_MATCH_DETAIL_SUCCESS",

  GET_CLIENT_ANNU_REQUEST: "GET_CLIENT_ANNU_REQUEST",
  GET_CLIENT_ANNU_SUCCESS: "GET_CLIENT_ANNU_SUCCESS",

  SET_BET_SUCCESS: "SET_BET_SUCCESS",
  CLEAR_BET_SUCCESS: "CLEAR_BET_SUCCESS",

  FAILED_RESPONSE: "FAILED_RESPONSE",
};
