import { changePasswordLogin } from "App/_redux/_services";
import { InputField } from "App/components/Common";
import { checkFormError, getResposeError } from "App/helper";
import { changePasswordSchema } from "App/helper/errorSchema";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { logoutUser } from "App/_redux/_actions"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [formError, setFormError] = useState({});

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const stateObj = {
      ...formValue,
      [name]: value,
    };
    setFormValue(stateObj);
    if (!!formError) {
      const error = await checkFormError(stateObj, changePasswordSchema);
      setFormError(error);
    }
  };

  const submit = async () => {
    const validationResult = await checkFormError(
      formValue,
      changePasswordSchema
    );
    if (validationResult === true) {
      try {
        const res = await changePasswordLogin(formValue);
        if (res) {
          toast.success(res?.message);
          logout()
        }
      } catch (err) {
        toast.error(getResposeError(err?.error));
      }
    } else {
      setFormError(validationResult);
    }

  };
  const logout = () => {
    dispatch(logoutUser((res) => {
      navigate("/login")
    }))
  }

  return (
    <div className="innrRight_singleBx">

      <div className="changePw_container">
        <h4 className="h4_semiTxt mb-2 flex items-center gap-2"> <AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Change Password</h4>

        <div className="flex flex-wrap gap-x-5">
          <div className="basis-[calc(100%)] sm:basis-[calc(33.33%-13.5px)]">
            <InputField
              label="Old password"
              labelSpan="*"
              labelSpanClass="style_for_star"
              type="password"
              placeholder="********"
              name="old_password"
              focus={!!formError?.old_password}
              error={formError?.old_password}
              value={formValue.old_password}
              onChange={handleChange}
            />
          </div>
          <div className="basis-[calc(100%)] sm:basis-[calc(33.33%-13.5px)]">
            <InputField
              labelSpan="*"
              labelSpanClass="style_for_star"
              type="password"
              placeholder="********"
              label="New password"
              name="new_password"
              focus={!!formError?.new_password}
              error={formError?.new_password}
              value={formValue.new_password}
              onChange={handleChange}
            />
          </div>
          <div className="basis-[calc(100%)] sm:basis-[calc(33.33%-13.5px)]">
            <InputField
              labelSpan="*"
              labelSpanClass="style_for_star"
              type="password"
              placeholder="********"
              label="Confirm password"
              name="confirm_password"
              focus={!!formError?.confirm_password}
              error={formError?.confirm_password}
              value={formValue.confirm_password}
              onChange={handleChange}
            />
          </div>

          <div className=" max-w-28 w-full">
            <button
              type="submit"
              onClick={() => submit()}
              className="_btnSave"
            >
              Submit
            </button>
          </div>
        </div>
      </div>


    </div>
  );
};
