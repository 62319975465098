import { ledgerConstants } from "App/_redux/_constants";
import { getStorage } from "App/utils";

let initialState = {
  ledgerData: [],
  isLoader: false
};

export function ledger(state = initialState, action) {
  switch (action.type) {
    case ledgerConstants.GET_CLIENT_LEDGER_REQ:
      return {
        ...state,
        isLoader: true,
      };
    default:
      return state;
  }
}
