
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { casinoLogin, getUserbalance } from "App/_redux/_services";
import { Carousel } from 'react-responsive-carousel';
import { Images } from 'App/constants';
import _ from "App/helper/_";
import { LoginModal } from "App/modals";
import { useDispatch, useSelector } from 'react-redux';
import { CasinoMarket, CasinoSidebar } from 'App/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getCasinoBannersRequest, toggleAuthModalRequest, updateUserBalance } from 'App/_redux/_actions';
import toast from 'react-hot-toast';
import { useScreenDetector } from 'App/hooks/useScreenDetector';
import Footer from 'App/components/Layout/Footer';
import { BsChevronDown } from 'react-icons/bs';

import io from "socket.io-client";
import config from "App/lib/app-config";
import SocketEvent from 'App/context/SocketEvent';
import { DotLoader } from 'react-spinners';

const IndianCasino = () => {

    const { isMobile } = useScreenDetector();
    const { currentUser } = useSelector((state) => state.auth);
    const { masterData, GlobalAnnouncement, casinoBannerList } = useSelector((state) => state.match);
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [casinoFilter, setCainoFilter] = useState({ type: 'ALL' })
    const [showModal, setShowModal] = useState(false);
    const [casinoUrl, setCasinoUrl] = useState(null);
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    useEffect(() => {
        // if (params.provider_code && params.provider_code != "") {
        //     fetchCasino(params.provider_code, params.game_code)
        // }
    }, [])

    useEffect(() => {

        // if (casinoBannerList == null) {
        //     let param = {
        //         type: "Casino"
        //     }
        //     dispatch(getCasinoBannersRequest(param, (response) => {
        //         if (response && response.data) {

        //         }
        //     }))
        // }
    }, []);

    const fetchCasino = async (provider_code, game_code) => {
        setLoading(true)
        let param = { provider_code }
        if (params.game_code && params.game_code != "") {
            param['gameCode'] = game_code
        }

        try {
            const res = await casinoLogin(param);
            if (res.launchURL == null) {
                setLoading(false)
                toast.error('Launching next week.')
                navigate(-1)
            }
            if (res.launchURL) {
                setCasinoUrl(res.launchURL)
                setLoading(false)
            }
        } catch (error) {
            navigate(-1)
            setLoading(false)
        }
    }

    const dummyFun = () => { }


    const createConnection = useCallback((socket) => {
        socket.on("connect", () => {
            setConnected(socket.connected);
        });
        socket.on(SocketEvent.UPDATE_BALANCE, handleConnected);
    }, []);

    useEffect(() => {
        let socket = io(config.socketurl, {
            reconnectionDelayMax: 100000,
            extraHeaders: {},
        });
        createConnection(socket);
        return () => {
            closeRoom(socket, "payin_callback");
            socket.off(SocketEvent.UPDATE_BALANCE, handleConnected);
            socket.off();
            socket.disconnect();
        };
    }, [createConnection]);

    const closeRoom = (socket, pay) => {
        socket.emit("unsubscribe", pay);
    };

    const handleConnected = (payload) => {
        if (payload) {
            getUserbalance()
                .then((res) => {
                    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
                    currentUser.balance = res.data.balance;
                    currentUser.exposure = res.data.exposure;
                    currentUser.bonus_balance = res.data.bonus_balance;
                    currentUser.withdrawal_balance = res.data.withdrawal_balance;
                    localStorage.setItem("currentUser", JSON.stringify(currentUser));
                    dispatch(updateUserBalance(res.data))
                })
                .catch((error) => {
                    //  callback()
                });
        }
    };

    return (
        <Fragment>
            {loading ?
                <div className="midLayout h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar pb-2">
                    <div className="flex justify-center items-start text-center py-12  h-screen">
                        <div className="px-24 py-8 bg-secondary text-secondary-foreground flex justify-center items-center">
                            <DotLoader color="#0b81db" />
                        </div>
                    </div>
                </div>
                :
                <Fragment>
                    {casinoUrl && casinoUrl != null ?
                        <div className='w-full h-screen fixed top-0 left-0 z-[200]'>
                            <Link to="/casino" className='absolute right-2 top-2 z-[201] bg-gray-50 px-[0.7rem] py-1 rounded-full cursor-pointer text-center font-bold' ><p>X</p></Link>
                            <iframe className='w-full h-screen' src={casinoUrl} frameborder="0" ></iframe>
                        </div>
                        :
                        <Fragment>
                            <div className="midLayout h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar pb-2">
                                <div className="flex anvMarquee  h-[28px] md:h-7 items-center md:my-1 md:rounded overflow-hidden">
                                    <img className="h-4 px-1" src={Images.INFO.default} />
                                    <marquee className="text-[12px] flex items-center font-semibold " behavior="" direction="" >
                                        {GlobalAnnouncement != null ? GlobalAnnouncement.client_msg : `“Welcome to Velocity999 !!”`}
                                    </marquee>
                                </div>




                                {masterData && masterData.diamond_casino && <div className='bg-secondary text-secondary-foreground rounded-[8px]'>
                                    <h4 className="p-3 text-[16px]  font-semibold basis-full mb-[8px] sm:mb-4">
                                        Indian Casino
                                    </h4>

                                    <div className="flex flex-wrap gap-[12px] p-3">
                                        {masterData.diamond_casino.map((item, idx) => {
                                            return (
                                                <Link to={`/casino/indian_casino/${(item.game_code).replace(/\//g, '&&')}`} key={idx} className="inrCasino-items ">
                                                    <img src={item.image} alt="" className="thumbImg " />
                                                    <div className=' '>

                                                    </div>
                                                </Link>
                                            )
                                        })}

                                    </div>
                                </div>}




                                <Footer />
                            </div>
                            <div className="w-[368px] xl:block hidden h-[auto] md:h-[calc(100vh-64px)] overflow-auto no-scrollbar">
                                <CasinoSidebar />
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }
        </Fragment>
    )
}

export default IndianCasino