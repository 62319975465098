import React, { Fragment } from 'react'
import { Images } from "App/constants";
import Swal from 'sweetalert2'


const PlaceBetMessageModel = ({ showModal, toggle, placeBetMessage, betErro }) => {
    return (
        <Fragment>
            {showModal ? (
                <div className="loginModal_otr" >
                    <div class="relative w-full max-w-[503px] max-h-full z-10">
                        <div class={"relative rounded-lg shadow bg-white " + (betErro ? "bg-red-6" : "bg-green-7")}>

                            <div class="loginModal_header">
                                <div className='basis-full flex justify-end'>
                                    <button onClick={toggle} type="button" class="anvModal_close" data-modal-hide="authentication-modal">
                                        <svg class="w-3 h-3" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLnejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>

                            </div>


                            <div className="px-[52px] pt-4 pb-[60px]">
                                <div className="space-y-4 text-center" >
                                    <p className="text-white font-semibold">{placeBetMessage}</p>
                                </div>
                                <button type="submit" onClick={toggle} className="w-full 
                                 text-black  bg-white overflow-hidden relative mt-10 focus:outline-none font-medium rounded-lg text-sm px-2 py-2.5 text-center  focus:ring-[#D8AA32]">
                                    <span className='relative z-10'>Close</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='backDrop'></div>
                </div>
            ) : null}
        </Fragment>
    )
}

export default PlaceBetMessageModel