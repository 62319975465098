import React from "react";
import { Link } from "react-router-dom";

export const Game = () => {
  return <div className="containerMain_bx dark:bg-black">
    <div className="gameContainer">
      <div className="gameMenu_bx">

        <Link to={"/casino"} className="activeGame basis-[116px] sm:basis-[170.14px] ">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/casino.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Casino</h6>
            </div>
          </div>
        </Link>

        <Link to={"/profit-loss"} className="basis-[116px] sm:basis-[170.14px]">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/profit_&_loss.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Profit & Loss</h6>
            </div>
          </div>
        </Link>

        <Link to={"/statement"} className="basis-[116px] sm:basis-[170.14px]">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/statement.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Statement</h6>
            </div>
          </div>
        </Link>

        <Link to={"/rules"} className="basis-[116px] sm:basis-[170.14px]">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/rule.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Rules</h6>
            </div>
          </div>
        </Link>

        {/* <Link to={"/setting"} className="basis-[116px] sm:basis-[170.14px]">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/set_button_value.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Set Button Values</h6>
            </div>
          </div>
        </Link> */}

        <Link to={"/change-password"} className="basis-[116px] sm:basis-[170.14px]">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/password.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Password</h6>
            </div>
          </div>
        </Link>

        <Link to={"/setting"} className="basis-[116px] sm:basis-[170.14px]">
          <div className="inrGame_bx">
            <div className="">
              <img src="/game/setting.png" alt="" />
            </div>
            <div>
              <h6 className="gameTxt">Settings</h6>
            </div>
          </div>
        </Link>

      </div>
    </div>

  </div>;
};
