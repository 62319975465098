import React from "react";
import { Images } from "App/constants";
import { Link } from "react-router-dom";
import { getStorage } from "App/utils";
import { useSelector } from "react-redux";

export default function Footer() {
  const { currentUser } = useSelector((state) => state.auth);

  const logos = [
    { src: Images.EGUZI_LOGO, alt: 'Eguzi Logo' },
    { src: Images.AG_LOGO, alt: 'AG Logo' },
    { src: Images.BET_GAMES_LOGO, alt: 'Bet Games Logo' },
    { src: Images.EVO_LOGO, alt: 'Evolution Games Logo' },
    // { src: Images.NETENT_LOGO, alt: 'NetEnt Logo' },
    // { src: Images.TVBET_LOGO, alt: 'TVBet Logo' },
    { src: Images.SUPER_SPADE_LOGO, alt: 'Super Spade Logo' },
    { src: Images.SUPERNOVA_LOGO, alt: 'Supernova Logo' },
    // { src: Images.BTI_LOGO, alt: 'BTI Logo' },
    // { src: Images.MOJ_LOGO, alt: '7Mojos Logo' },
    { src: Images.SPRIBE_LOGO, alt: 'Spribe Logo' },
  ];
  const payment_methods = [
    { src: Images.CRYPTO_LOGO, alt: 'net bank Logo' },
    { src: Images.NET_BANK_LOGO, alt: 'net bank Logo' },
    { src: Images.UPI_LOGO, alt: 'upi Logo' },
    { src: Images.GPAY_LOGO, alt: 'gpay  Logo' },
    { src: Images.PAYTM_LOGO, alt: 'paytm Logo' },
    { src: Images.PHONEPE_LOGO, alt: 'PHONEPE_LOGO Logo' },
  ];
  const social = [
    { src: Images.TELEGRAM.default, alt: 'telegram Logo' },
    { src: Images.WHATSAPP.default, alt: 'whatsapp Logo' },
  ];

  return (
    <footer className=" footerMain_bx">

      <div className="anvCopy_write">

        <ul className="ft_contentLeft_ul">
          <li>
            <Link to={currentUser && currentUser.username ? "/promotions" : "/promotions-d"}>
              Promotions
            </Link>
          </li>

          <li>
            <Link to={currentUser && currentUser.username ? "/terms" : "/terms-d"}>
              Terms and Conditions
            </Link>
          </li>

          {/* <li>
            <Link to={currentUser && currentUser.username ? "/rules" : "/rules-d"}>
              Betting Rules
            </Link>
          </li>

          <li>
            <Link to={currentUser && currentUser.username ? "/virtual-rules" : "/virtual-rules-d"}>
              Virtual Rules
            </Link>
          </li> */}

          <li>
            <Link to={currentUser && currentUser.username ? "/responsible-gaming" : "/responsible-gaming-d"}>
              Responsible Gaming
            </Link>
          </li>

          <li>
            <Link to={currentUser && currentUser.username ? "/privecy" : "/privecy-d"}>
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to={currentUser && currentUser.username ? "/about" : "/about-d"}>
              About Us
            </Link>
          </li>
          <li>
            <Link to={currentUser && currentUser.username ? "/affiliate-program" : "/affiliate-program-d"}>
              Affiliate Program
            </Link>
          </li>
          <li>
            <Link to={currentUser && currentUser.username ? "/support" : "/support-d"}>
              Support
            </Link>
          </li>
        </ul>

        <div className="pt-[15px] flex flex-col gap-[10px]">
          <p className="text-xs text-center">Official Betting Sponsors</p>
          <div className="flex gap-6 md:gap-12 items-center justify-center flex-wrap">
            <img src={Images.FANDUEL} style={{ maxHeight: '35px' }} />
          </div>
        </div>

        <div className="pt-[15px] flex flex-col gap-[20px]">

          <p className="text-xs text-center">Gaming Partners</p>

          <div className="flex gap-6 md:gap-12 items-center flex-wrap">
            {logos.map((logo, index) => (
              <a key={index} href="#" className="flex-shrink-0" >
                <img src={logo.src} alt={logo.alt} className=" transition-transform transform h-[0.65rem] md:h-6 hover:scale-110"
                />
              </a>
            ))}
          </div>

          <p className="text-xs text-center">Payment Methods</p>

          <div className="flex gap-6 md:gap-12 justify-center items-center flex-wrap">
            {payment_methods.map((pay, index) => (
              <a key={index} href="#" className="flex-shrink-0" >
                <img src={pay.src} alt={pay.alt} className=" transition-transform transform h-[0.65rem] md:h-4 hover:scale-110"
                />
              </a>
            ))}
          </div>

          <p className="text-xs text-center">Connect With Us</p>

          <div className="flex gap-6 md:gap-12 justify-center items-center flex-wrap">
            {social.map((item, index) => (
              <a key={index} href="#" className="flex-shrink-0" >
                <img src={item.src} alt={item.alt} className=" transition-transform transform h-6 md:h-6 hover:scale-110"
                />
              </a>
            ))}
          </div>


          {/* <div>
            <p className="text-xs text-center">Restricted to <span className="font-semibold"> 18+</span></p>
            </div>

          <div className="flex gap-6 md:gap-12 items-center flex-wrap">
            <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
              <img src={Images.TELEGRAM.default} alt="Telegram" className="w-6 h-6 md:w-8 md:h-8 transition-transform transform hover:scale-110" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={Images.INSTAGRAM.default} alt="Instagram" className="w-6 h-6 md:w-8 md:h-8 transition-transform transform hover:scale-110" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={Images.FACEBOOK.default} alt="Facebook" className="w-6 h-6 md:w-8 md:h-8 transition-transform transform hover:scale-110" />
            </a>
            <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer">
              <img src={Images.WHATSAPP.default} alt="WhatsApp" className="w-6 h-6 md:w-8 md:h-8 transition-transform transform hover:scale-110" />
            </a>
            <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
              <img src={Images.DISCORD.default} alt="Discord" className="w-6 h-6 md:w-8 md:h-8 transition-transform transform hover:scale-110" />
            </a>
          </div>
          <div className="flex gap-[25px] items-end flex-wrap">
            <img src={Images.GROUPCOPY1} alt="" />
            <img className="max-w-[400px] w-full md:w-auto md:max-w-[100%]" src={Images.GROUPCOPY2} alt="" />
          </div> */}



        </div>
      </div>
      <div className="text-center pt-5">
        <small>Copyright ©️ 2024 Gamebey. All rights reserved.</small>
      </div>

    </footer >
  );
}
