import { affiliateRequest } from 'App/_redux/_services';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';

const AffiliateRequest = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
    });

    const [errors, setErrors] = useState({
        name: '',
        mobile: '',
        email: '',
    });



    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Basic validation (this can be improved or extended as needed)
        if (value.trim() === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: 'This field is required.',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Simple form validation
        if (Object.values(errors).some((error) => error !== '')) {
            console.log('Form has errors:', errors);
            return;
        }

        setLoading(true)

        try {
            const res = await affiliateRequest(formData);
            setLoading(false)
            if (res && res.message != "") {
                toast.success(res.message)
                setFormData({ name: '', mobile: '', email: '' });
                navigate('/')
            }

        } catch (error) {
            setLoading(false)
        }

        // Reset form fields
    };

    return (
        <div className="innrRight_singleBx">
            <div className="st_featured_thumb">
                <div className="text_wrper">
                    <div className="st_hd1">
                        <h4 className="h4_semiTxt py-[10px] px-2 flex items-center gap-2">
                            <AiOutlineArrowLeft className='cursor-pointer' onClick={() => navigate(-1)} />
                            Affiliate Request
                        </h4>
                    </div>
                    <div className="mainDepos_bx">
                        <div className="w-full mx-auto mt-3 p-6 rounded-lg">
                            <h2 className="text-2xl font-bold mb-4">Request Information</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border-[1px] rounded-lg focus:outline-none ${errors.name ? 'border-red-600' : 'focus:border-blue-500'}`}
                                        aria-required="true"
                                        aria-invalid={!!errors.name}
                                        required
                                    />
                                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="mobile" className="block text-gray-700 text-sm font-bold mb-2">Mobile</label>
                                    <input
                                        type="tel"
                                        id="mobile"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border-[1px] rounded-lg focus:outline-none ${errors.mobile ? 'border-red-600' : 'focus:border-blue-500'}`}
                                        aria-required="true"
                                        aria-invalid={!!errors.mobile}
                                        required
                                    />
                                    {errors.mobile && <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className={`w-full px-3 py-2 border rounded-lg focus:outline-none ${errors.email ? 'border-red-500' : 'focus:border-blue-500'}`}
                                        aria-required="true"
                                        aria-invalid={!!errors.email}
                                        required
                                    />
                                    {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                                </div>

                                <button
                                    type="submit"
                                    className="w-full bg-active text-active-foreground py-2 px-4 rounded-lg hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500"
                                >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AffiliateRequest;
