import React, { Fragment, useState } from 'react'
import Countdown, { zeroPad } from 'react-countdown';

const Timer = ({ EventDetails }) => {
    const [setTimeout, setTimer] = useState(true)

    let matchTime = EventDetails.open_date ? Date.parse(EventDetails.open_date) + (Math.abs(new Date().getTimezoneOffset()) * 60000) : null;
    let currentTime = new Date().getTime();

    return (
        <Fragment>
            {EventDetails.open_date && currentTime <= matchTime && <Countdown
                date={Date.parse(EventDetails.open_date) + (Math.abs(new Date().getTimezoneOffset()) * 60000)}
                zeroPadTime={2}
                daysInHours={true}
                renderer={props =>
                    <Fragment>
                        {props.days <= 2 && props.hours <= 24 &&
                            <Fragment>
                                {/* <div className="hidden md:flex gap-5">
                                    <div className='text-center'>
                                        <h4 className="text-4xl text-[#262626] dark:text-white font-bold">
                                            {zeroPad(props.days)}
                                        </h4>
                                        <p className="text-4xl text-[#363636] dark:text-[#eee]">
                                            Days
                                        </p>
                                    </div>

                                    <div className='text-center'>
                                        <h4 className="text-4xl text-[#262626] dark:text-white font-bold">
                                            {zeroPad(props.hours)}
                                        </h4>
                                        <p className="text-4xl text-[#363636] dark:text-[#eee]">
                                            Hours
                                        </p>
                                    </div>

                                    <div className='text-center'>
                                        <h4 className="text-4xl text-[#262626] dark:text-white font-bold">
                                            {zeroPad(props.minutes)}
                                        </h4>
                                        <p className="text-4xl text-[#363636] dark:text-[#eee]">
                                            Minutes
                                        </p>
                                    </div>

                                    <div className='text-center'>
                                        <h4 className="text-4xl text-[#262626] dark:text-white font-bold">
                                            {zeroPad(props.seconds)}
                                        </h4>
                                        <p className="text-4xl text-[#363636] dark:text-[#eee]">
                                            Seconds
                                        </p>
                                    </div>
                                </div> */}
                                <div className='flex  gap-[0.8rem]'>
                                    <div className='text-center'>
                                        <div className='timerDhms'>{zeroPad(props.days)}</div>
                                        <div className='timer_titleDhms'>Days</div>
                                    </div>
                                    <div className='text-center'>
                                        <div className='timerDhms'>{zeroPad(props.hours)}</div>
                                        <div className='timer_titleDhms'>Hours</div>
                                    </div>
                                    <div className='text-center'>
                                        <div className='timerDhms'>{zeroPad(props.minutes)}</div>
                                        <div className='timer_titleDhms'>Minutes</div>
                                    </div>
                                    <div className='text-center'>
                                        <div className='timerDhms'>{zeroPad(props.seconds)}</div>
                                        <div className='timer_titleDhms'>Seconds</div>
                                    </div>
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                }
                onComplete={() => { setTimer(false) }}
            />}
        </Fragment>
    )
}

export default Timer