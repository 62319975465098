import {
  FormFeedback,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

export const InputField = (props) => {
  const {
    error,
    label,
    focus,
    preAddon = false,
    postAddon = false,
    labelClass,
    boxClass,
    labelSpan,
    labelSpanClass,
  } = props;
  const invalid = !!(focus && error);
  const valid = !!(focus && !error);

  return (
    <FormGroup className={boxClass}>
      {label && (
        <Label className={labelClass}>
          {label}
          <span className={labelSpanClass}>{labelSpan}</span>
        </Label>
      )}
      <InputGroup className="anvTxt_fielW">
        {preAddon && <InputGroupText>{preAddon}</InputGroupText>}
        <Input invalid={invalid} valid={valid} {...props} autoComplete="off" />
        {postAddon && <InputGroupText>{postAddon}</InputGroupText>}
      </InputGroup>
      {invalid && <FormFeedback>{error}</FormFeedback>}
    </FormGroup>
  );
};
