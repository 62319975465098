import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { routers } from "./App";
import App from "App/App"
// import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
// import { ToastContainer } from './App/Component';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { store } from "App/_redux/_helpers";
import { Toaster } from "react-hot-toast";
import PWAPrompt from 'react-ios-pwa-prompt';
import * as serviceWorker from "./serviceWorker"


const root = createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <RouterProvider router={routers} /> */}
    <App />
    {/* <PWAPrompt
      promptOnVisit={1}
      timesToShow={3}
      copyClosePrompt="Close"
      permanentlyHideOnDismiss={false}
    /> */}

    <ToastContainer />
    <Toaster
      position="top-center"
      reverseOrder={false}
    />
  </Provider>
  // </React.StrictMode>
);

serviceWorker.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
